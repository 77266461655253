.body-content {
  display: flex;
  flex-direction: column;
  margin: 5% 5% 5% 5%;
  position: relative;
  @media (max-width: 490px) {
    display: block;
  }
}
.body-content_text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.body-content_text-1 {
  // font-family: rubik;
  // text-transform: uppercase;
  font-size: 3rem;
  font-weight: 600;
  margin: 0;
  line-height: 1.32;
  margin-bottom: 1rem;
  text-align: center;
  @media (max-width: 500px) {
    font-size:1.7em;
  }
}
.body-content_text-2 {
  font-size: 1.1rem;
  width: 50%;
  margin: 0;
  text-align: center;
  font-weight: 200;
}
.body-content_card {
  display: grid;
  grid-gap: 5%;
  align-self: center;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  z-index: 3;
  margin: -2% 0;
  padding: 5% 5% 2rem 5%;
  background-image: url('../../../assest/Home/network-back_540.png');
  background-position: center;
  background-size: contain;
  opacity: 0.8;
  position: relative;
  width: 100%;
  background-repeat: repeat;
}
.body-content_card-1 {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 2rem 2rem 2rem 2rem;
  // width: 65%;
  border-radius: 5.5px;
  box-shadow: 0 1.5px 25px 0 rgba(105, 48, 195, 0.1);
  // height: 450px;
}
.body-content_card-icon {
  align-self: center;
  width: 20%;
  margin-bottom: 1rem;
}
.body-content_card-title {
  text-align: center;
  font-weight: 600;
  font-size: 0.95rem;
  min-height: 3.5em;
}
.body-content_card-desc {
  text-align: center;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  max-height: 12em;
  min-height: 12em;
  text-align: justify;
  width: 95%;
}
.body-content_card-btn {
  align-self: center;
  margin: 1rem 0 1rem 0;
  // text-transform: uppercase;
  border: none;
  width: 110px;
  height: 30px;
  background: transparent;
  color: #9a0cec;
  font-weight: 600;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
