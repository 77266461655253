.static-card {
  display: grid;
  margin-left: 5%;
  margin-right: 5%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5%;
  height: 100%;
  font-family: rubik;
  color: #ffffff;
  align-items: center;
}
#image_id {
  align-self: center;
  margin: 5% 5% 5% 5%;
}
.static-card_1 {
  display: flex;
  margin: 7.5% 5% 0 5%;
  flex-direction: column;
  padding: 4% 2% 0 2%;
  background: linear-gradient(to bottom, #9a0cec, #48bfe3);
  text-align: center;
  height: 40em;
  position: relative;
  box-shadow: alpha($color: #000000);
  border-radius: 5px;
}
.static-card-text_bold {
  margin-top: 2rem;
  font-size: 1rem;
  font-weight: 600;
  min-height: 5em;
  margin-bottom: 0;
}
.static-card-text_light {
  font-size: 0.85rem;
  line-height: 1.2;
  padding: 0 2%;
  text-align: center;
  margin-top: 10%;
  color: white;
  min-height: 10em;
}
.static-card_btn {
  width: 7rem;
  height: 2.5rem;
  margin: 4rem;
  padding: 0.8rem 0 0.8rem 0;
  border-radius: 2px;
  background-color: #ffffff;
  font-weight: 600;
  border: none;
  align-self: center;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
.phone__img {
  align-self: center;
  width: 80%;
  height: auto;
  // position: absolute;
  // bottom: -16rem;
  // opacity: 0.9;
  // margin: 8rem 2rem 2rem 2rem;
}
.icon {
  width: 4em;
  align-self: center;
  margin: 1rem;
}
.icon-1 {
  width: 90px;
  align-self: center;
  margin: 2rem;
}
