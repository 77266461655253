$white: #ffffff;
$charcoal-grey: #323132;
.header-content {
  width: 55%;
  height: 100%;
  margin-top: 17.5%;
  margin-left: 5%;
  @media (max-width: 1200px) {
    margin-top: 15%;
  }
  @media (max-width: 1200px) {
    margin-top: 10%;
  }
  // @media (max-width: 900px) {
  //   margin-top: 30%;
  // }
  // @media (max-width: 700px) {
  //   margin-top: 40%;
  // }
  // @media (max-width: 600px) {
  //   position: absolute;
  //   bottom: 0px;
  //   margin: 0px;
  //   top: 24%;
  //   left: 14%;
  // }
}
.content-1 {
  width: 50%;
  height: 100px;
  margin: 0 0 10px;
  font-family: Titillium Web;
  font-size: 2.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: -0.39px;
  text-align: left;
  color: $white;
  @media (max-width: 1200px) {
    font-size: 2.5rem;
  }
  // @media (max-width: 900px) {
  //   font-size: 2rem;
  //   margin: 0 0 6px;
  //   width: 100%;
  // }
  // @media (max-width: 660px) {
  //   font-size: 1.4rem;
  // }
  // @media (max-width: 600px) {
  //   font-size: 1.1rem;
  //   height: 30px;
  // }
  // @media (max-width: 480px) {
  //   font-size: 0.9rem;
  // }
}
.text-style-1 {
  font-weight: bold;
  width: 150%;
  margin: 10px;
  // @media (max-width: 600px) {
  //   // margin: 0px;
  //   // padding: 0px;
  //   // padding-top: 10px;
  // }
}
.text-style-2 {
  margin-top: -5px;
  height: 100%;
  width: 200%;
  padding-left: 10px;
  // @media (max-width: 600px) {
  //   margin: 0px;
  //   padding: 0px;
  //   margin-top: 5px;
  // }
}
.content-2 {
  width: 80%;
  height: 70px;
  margin-top: 3rem;
  font-family: Titillium Web;
  font-size: 1.1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: 0.43px;
  text-align: left;
  color: $white;
  padding: 60px 0;

  // @media (max-width: 1200px) {
  //   font-size: 1rem;
  // }
  // @media (max-width: 980px) {
  //   font-size: 0.8rem;
  // }
  // @media (max-width: 900px) {
  //   font-size: 0.7rem;
  //   width: 80%;
  //   margin-top: 1rem;
  // }
  // @media (max-width: 600px) {
  //   font-size: 0.6rem;
  //   margin-top: 1rem;
  // }
}
.text-style-3 {
  width: 125%;
  padding-left: 10px;
  font-size: 1.27rem;
  margin-top: 15px;

  // @media (max-width: 600px) {
  //   margin: 0px;
  //   padding: 0px;
  //   padding-top: 20px;
  // }
}
.header-button {
  display: flex;
  padding-left: 10px;
  margin-top: 5rem;
  // @media (max-width: 600px) {
  //   padding: 0px;
  // }
}

.button-header_1 {
  width: auto;
  height: 2.5rem;
  border: none;
  margin-right: 2rem;
  padding: 5.5px 13px 5px 12.5px;
  border-radius: 5px;
  background-color: $white;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  text-transform: capitalize;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  @media (max-width: 1200px) {
    width: 14rem;
  }
  @media (max-width: 900px) {
    width: 12rem;
    // margin-top: -1rem;
  }
  // @media (max-width: 600px) {
  //   width: 9rem;
  //   margin-top: -1.5rem;
  // }
  // @media (max-width: 600px) {
  //   width: 12rem;
  //   margin-top: -1.5rem;
  //   padding: 1px;
  //   margin-right: 1rem;
  // }
}
.button-header_2 {
  width: auto;
  height: 2.5rem;
  padding: 5px 15px;
  text-align: center;
  border-radius: 5px;
  border: solid 2px #ffffff;
  background-color: transparent;
  cursor: pointer;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  &:focus {
    outline: none;
  }
  @media (max-width: 1200px) {
    width: 14rem;
  }
  @media (max-width: 900px) {
    width: 12rem;
    // margin-top: -1rem;
  }
  // @media (max-width: 600px) {
  //   width: 6rem;
  //   margin-top: -1.5rem;
  //   padding-left: 0.6rem;
  // }
  // @media (max-width: 480px) {
  //   width: 6rem;
  //   margin-top: -1.5rem;
  // }
}
.border-para_1 {
  font-family: Titillium Web;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: $charcoal-grey;
  margin: 0;
  padding-left: 0.2rem;
  text-transform: uppercase;
  font-weight: 300;
  @media (max-width: 1200px) {
    font-size: 0.8rem;
  }
  @media (max-width: 1150px) {
    font-size: 0.7rem;
  }
  @media (max-width: 970px) {
    font-size: 0.6rem;
  }
  @media (max-width: 600px) {
    text-align: center;
  }
}
.border-para_2 {
  font-family: Titillium Web;
  font-size: 1rem;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: $white;
  margin: 0;
  text-transform: uppercase;
  font-weight: 500;
  @media (max-width: 1200px) {
    font-size: 0.9rem;
  }
  @media (max-width: 980px) {
    font-size: 0.8rem;
  }
  @media (max-width: 900px) {
    font-size: 0.6rem;
  }
}
.overlap-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  height: auto;
  margin-left: 5%;
  margin-right: 2%;
  margin-top: 0!important;
  @media (max-width: 900px) {
    font-size: 0.6rem;
    // height: 100px;
    margin-left: 2%;
  }
}
.overlap-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.overlap-btn_1 {
  font-family: Titillium Web;
  font-weight: 400;
  width: 14rem;
  height: 2.5rem;
  border: none;
  padding: 5.5px 13px 5px 12.5px;
  border-radius: 5px;
  background-color: $white;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  font-size: 1rem;
  @media (max-width: 1200px) {
    font-size: 0.9rem;
    // width: 12rem;
  }
  @media (max-width: 900px) {
    width: 10rem;
    font-size: 0.7rem;
  }
}
.overlap-text {
  font-family: Titillium Web;
  display: flex;
  color: $white;
  flex-direction: column;
  margin: auto 20px auto 0px;
}
.overlap-text_1 {
  font-size: 2rem;
  font-weight: bold;
  margin: 0!important;
  justify-content: flex-start;
  letter-spacing: -0.3px;
  @media (max-width: 1200px) {
    font-size: 1.7rem;
  }
  @media (max-width: 900px) {
    font-size: 1.4rem;
  }
  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
}
.overlap-text_2 {
  font-size: 1.6rem;
  font-weight: lighter;
  margin: 0;
  line-height: 1.5;
  @media (max-width: 1200px) {
    font-size: 1.4rem;
  }
  @media (max-width: 900px) {
    font-size: 1.2rem;
  }
  @media (max-width: 600px) {
    font-size: 1.1rem;
  }
}
.overlap-text_3 {
  font-size: 1.1rem;
  font-weight: lighter;
  @media (max-width: 1200px) {
    font-size: 1.1rem;
  }
  @media (max-width: 900px) {
    font-size: 1rem;
  }
  @media (max-width: 600px) {
    font-size: 0.9rem;
  }
}
.overlap-text_3_link{
  font-size: 1rem;
  color: #ffffff;
  font-weight: lighter;
  @media (max-width: 1200px) {
    font-size: 1rem;
  }
  @media (max-width: 900px) {
    font-size: 1rem;
  }
  @media (max-width: 600px) {
    font-size: 0.9rem;
  }
}
.overlap-text_3_link:hover{
color: #ffffff;
}

.explore-button {
  text-decoration: none !important;
}

.overlap-btn_1 {
  text-align: center;
  color: black;
  opacity: 0.9;
  font-weight: 600;
}

a.overlap-btn_1:hover {
  color: black;
}
