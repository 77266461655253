.content-partners {
  display: grid;
  flex-direction: row;
  margin-top: -50px;
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
  }
}
.text-content-partner {
  text-align: left;
  color: #323132;
  opacity: 0.8;
  margin-top: 4%;
  font-size: 0.9rem;
  @media (max-width: 900) {
    font-size: 0.8rem;
  }
  @media (max-width: 600) {
    font-size: 0.7rem;
    margin-top: 3%;
  }
}
.overlap-partner-box {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: auto;
  background-color: white;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: none;
  align-self: center;
  box-shadow: 0 1.5px 18.5px 0 #00000029;
  padding-bottom: 2%;
  margin-top: -7%;
  margin-bottom: 50px;
}
.img-for-partner {
  width: 100%;
  margin-top: -4.5em;
}
.overlapPartner-2 {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: auto;
  background-color: white;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: none;
  align-self: center;
  box-shadow: 0 1.5px 18.5px 0 #00000029;
  margin-top: -2%;
  padding: 50px 50px;
  // @media (max-width: 1250px) {
  //   padding-bottom: 8%;
  // }
  // @media (max-width: 900px) {
  //   padding-bottom: 19%;
  // }
  // @media (max-width: 600px) {
  //   padding-bottom: 100px;
  // }
  @media (max-width: 550px) {
    padding: 25px 10px;
  }
}
.partner-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 25px 25px;
}
.card-container-partner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2% 2% 2% 2%;
  text-align: center;
  // width: 80%;
  border-radius: 5.5px;
  -webkit-border-radius: 5.5px;
  -moz-border-radius: 5.5px;
  -ms-border-radius: 5.5px;
  -o-border-radius: 5.5px;
  border: solid 0.5px #eed2ff;
  background-color: #fdf9ff;
  height: 280px;
  @media (max-width: 600px) {
    margin: 0 20px;
  }
}
.icon-for-partner {
  width: 30%;
  margin-bottom: 7%;
  margin-top: 10%;
}
.title-for-partner {
  // text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  color: #323132;
  margin: 5%;
  opacity: 0.8;
}
.summary-for-partner {
  font-family: Rubik;
  line-height: 1.5;
  font-size: 0.8rem;
  color: #323132;
  margin: 5% 2% 2% 2%;
}
.brand-content-box-2 {
  align-self: center;
  width: 60%;
  display: flex;
  background: #fff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border: none;
  padding-right: 5%;
  color: #323132;
  box-shadow: 0 1.5px 18.5px 0 #00000029;
  margin-top: 10%;
  @media (max-width: 650px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 0;
    margin-top: 15%;
    width: 75%;
    .brand-content-box-img-2 {
      margin-left: 0;
      margin-top: -60px;
    }
    .brand-content-box_container-2 {
      text-align: center;
    }
    .brand-btn-card-2 {
      margin-left: 0;
    }
  }
}
.brand-content-box-img-2 {
  height: 20%;
  width: 70%;
  margin-top: -15%;
  margin-left: -5%;
  @media (max-width: 600px) {
    width: 300;
    height: 300;
    margin-top: -15%;
    margin-left: 10%;
  }
}
.brand-content-box_container-2 {
  padding-top: 10%;
  padding-left: 5%;
  padding-right: 2%;
  @media (max-width: 1111px) {
    width: 300px;
    padding-top: 3%;
    padding-left: 2%;
  }
  @media (max-width: 600px) {
    font-size: 0.8rem;
    color: #323132;
    opacity: 0.8rem;
    width: 95%;
  }
}
.brand-text_quote-auth-2 {
  // text-transform: uppercase;
  margin-top: 10%;
  padding-top: 5%;
  margin-bottom: 0;
  padding-bottom: 0;
}
.brand-name-2 {
  // text-transform: uppercase;
  margin-top: 5%;
  color: #9a0cec;
  font-weight: 600;
}
.brand-btn-card-2 {
  border: none;
  background: transparent;
  color: #9a0cec;
  margin: 21% 0 7% -35%;
  font-weight: 600;
  line-height: 1.6;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  @media (max-width: 600px) {
    margin-left: 15%;
    margin-top: 2%;
  }
}
.box-title-1 {
  color: #323132;
  opacity: 0.9;
  align-self: center;
  text-align: center;
  // text-transform: uppercase;
  margin-top: 5%;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.53;
  font-weight: bold;
  margin-left: 5%;
  margin-right: 5%;
  width: 60%;
  @media (max-width: 600px) {
    font-size: 1.2rem;
    margin-top: 3%;
  }
}

//forpartner
.headerHalf-main-partner {
  width: 100vw;
  height: 70vh;
  background-image: url('../../../assest/header-img/Partners.jpg');
  background-size: cover;
  background-position: center center;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10%;
  color: white;
}
.headerHalf-title {
  font-size: 3rem;
  margin: 0;
  line-height: 2.34;
  font-weight: bold;
}
.headerHalf-uris {
  font-size: 1.2rem;
  margin: 0;
  opacity: 0.8;
}
.uris-class {
  border-bottom: 1px solid white;
  margin: 0;
}

@media (max-width: 800px) {
  .headerHalf-main-partner > div {
    position: absolute;
    top: 10%;
  }

  .headerHalf-main-partner {
    padding-top: 60%;
    height: 0px;
  }

  p.headerHalf-title {
    font-size: 1.8rem;
  }
}
.headerHalf-content {
  @media (max-width: 450) {
    top: 10%;
  }
}

.logo-partners {
  display: grid;
  grid-gap: 2em;
  grid-template-columns: auto auto auto auto auto;
  margin: 3em;
  margin-top:-4em;
  @media (max-width:500px) {
    grid-template-columns: auto;
  }
  @media (max-width:700px) {
    grid-template-columns: auto;
  }
  @media (max-width:900px) {
    grid-template-columns: auto auto;
  }
}

img.partnerLogo {
  width: 100%; /* Set a uniform width */
  height: 200px; /* Set a uniform height to make it square */
  margin: auto 0;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add box shadow */
  object-fit: contain; /* Ensure the image fits within the given dimensions without distortion */
  display: block; /* Ensures the margin auto works correctly */
  border-radius: 5px;
}

img.partnerLogoICCA {
  width: 200px; /* Set a uniform width */
  height: 200px; /* Set a uniform height to make it square */
  margin: auto 0;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add box shadow */
  object-fit: contain; /* Ensure the image fits within the given dimensions without distortion */
  display: block; /* Ensures the margin auto works correctly */
  border-radius: 5px;
}
img.partnerLogoHera {
  width: 200px; /* Set a uniform width */
  height: 200px; /* Set a uniform height to make it square */
  margin: auto 0;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add box shadow */
  object-fit: contain; /* Ensure the image fits within the given dimensions without distortion */
  display: block; /* Ensures the margin auto works correctly */
  border-radius: 5px;
}

.partners-block {
  display: grid;
  //background-color: #fff;
  border-radius: 15px;
  padding-top: 2em;
  margin: 8%;
}

.partner-title {
  margin-bottom: 2em;
  text-align: center;
  // text-transform: uppercase;
  font-weight: 800;
  font-size: 2.5rem;
}