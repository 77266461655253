.headerHalf-main-distributeevc {
    width: 100vw;
    height: 70vh;
    background-image: url('../../../assest/Replace\ Header.png')!important;
    background-size: cover;
    background-position: center center;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10%;
    color: white;
  }
  .headerHalf-title {
    font-size: 3rem;
    margin: 0;
    line-height: 2.34;
    font-weight: bold;
  }
  .headerHalf-uris {
    font-size: 1.2rem;
    margin: 0;
    opacity: 0.8;
  }
  .uris-class {
    border-bottom: 1px solid white;
    margin: 0;
  }
  
  @media (max-width: 800px) {
    .headerHalf-main > div {
      position: absolute;
      top: 10%;
    }
  
    .headerHalf-main {
      padding-top: 60%;
      height: 0px;
      background-size: contain;
    }
  
    p.headerHalf-title {
      font-size: 1.8rem;
    }
  }
  .headerHalf-content {
    @media (max-width: 450) {
      top: 10%;
    }
  }



  .whatmore {
    //margin-top: 3em !important;
    padding-bottom: 0 !important;
    z-index: 90;
  }
  
  .kys-platform-content {
    flex: 15%;
    @media screen and (max-width: 600px) {
      .column {
        width: 100%;
      }
    }
  }
  
  .kys-platform-img {
    overflow: visible;
    flex: 20%;
    @media screen and (max-width: 600px) {
      .column {
        width: 100%;
      }
    }
  }
  
  .kys-platform-imagee {
    display: block; /* Ensure the image is displayed */
    //margin: 20px auto;
  //max-width: 60%; /* Ensure the image is responsive */
  height: auto; /* Maintain aspect ratio */
    @media (max-width: 600px) {
      //display: none;
      max-width: 85%;
    }
  }

  .kys-platform-imagee1 {
    display: block; /* Ensure the image is displayed */
    margin: 10px auto 10px auto!important;
   // margin-bottom: -30px;
  max-width: 60%; /* Ensure the image is responsive */
  height: auto; /* Maintain aspect ratio */
  border: 5px solid #48bfe3;
    @media (max-width: 600px) {
      //display: none;
      max-width: 85%;
      margin-bottom: 5px;
    }
  }
  
  .kys-div {
    display: flex;
    align-self: center;
    margin: 6%;
    @media (max-width: 700px) {
      display: block;
    }
  }


  .cards-div-industries {
    display: grid;
    height: 90%;
    grid-template-columns: repeat(auto-fit, minmax(18em, 1fr));
    grid-gap: 30px 30px;
    margin: 5%;
    @media (max-width: 600px) {
      align-items: center;
      grid-gap: 20px 20px;
    }
  }
  .large-card {
    // max-width: 300px;
    background-color: white;
    // min-height: 350px;
    // width: 85%;
    padding-top: 3%;
    height: 420px!important;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border: solid 0.5px #e2e2e2;
    box-shadow: 0 1.5px 25px 0 #6930c31a;
    position: relative;
    @media (max-width: 600px) {
      height: 450px;
    }
  }
  .large-card-imgg {
    // width: 100%;
    height: 35%;
    padding-top: 10px;
    align-self: center;
    object-fit: cover;
    border-radius: inherit;
    @media (max-width: 600px) {
      height: 27%;
    }
  }
  .large-card-title {
    text-align: center;
    // text-transform: uppercase;
    margin: 6% 5% 2% 5%;
    color: #323132;
    line-height: 1.33;
    font-weight: bold;
    opacity: 0.9;
  }
  .large-card-btn {
    width: 100%;
    height: 40px;
    // margin-top: 310px;
    bottom: 0;
    position: absolute;
    background-color: rgba(239, 239, 239, 0.3);
    text-transform: uppercase;
    border: none;
    border-top: 0.5px solid #707070;
    color: #9a0cec;
    font-weight: bold;
    line-height: 1.5;
    justify-self: flex-end;
    position: absolute;
  }
  .large-card-desc {
    text-align: left;
    color: #323132;
    font-weight: 400;
    font-size: 1rem;
    margin: 1% 5% 5% 5%;
    opacity: 0.8;
    padding: 3% 3% 2% 3%;
  }
  .extra-large-card {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to bottom, #9a0cec, #48bfe3);
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    max-width: 350px;
    @media (max-width: 500) {
      max-width: 270px;
    }
  }
  .large-card-div {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
    grid-gap: 3em;
    justify-items: center;
    margin-top: 5%;
    align-self: center;
    @media (max-width: 750px) {
      grid-gap: 20px 20px;
      width: 100%;
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
  }
  .extra-large-icon {
    margin-top: 10%;
    width: 25%;
  }
  .extra-large-card-desc {
    font-family: Rubik;
    margin: 10% 10% 15% 10%;
    text-align: center;
    color: white;
    font-size: 1rem;
    opacity: 0.9;
    font-weight: 300;
    line-height: 1.5;
  }
  .extra-large-card-button {
    width: 35%;
    height: 20%;
    border: none;
    background-color: white;
    margin-bottom: 10%;
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 1.54;
    opacity: 0.9;
  }
  .industries-text {
    @media (max-width: 600) {
      font-size: 0.8rem;
      font-weight: 500;
      opacity: 0.8;
    }
  }
  //@at-root
  .headerHalf-main-industry {
    width: 100vw;
    height: 70vh;
    background-image: url('../../../assest/header-img/Industries.jpg');
    background-size: cover;
    background-position: center center;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10%;
    color: white;
  }
  .headerHalf-title {
    font-size: 3rem;
    margin: 0;
    line-height: 2.34;
    font-weight: bold;
  }
  .headerHalf-uris {
    font-size: 1.2rem;
    margin: 0;
    opacity: 0.8;
  }
  .uris-class {
    border-bottom: 1px solid white;
    margin: 0;
  }
  
  @media (max-width: 800px) {
    .headerHalf-main-industry > div {
      position: absolute;
      top: 17%;
    }
  
    .headerHalf-main-industry {
      padding-top: 60%;
      height: 0px;
    }
  
    p.headerHalf-title {
      font-size: 1.8rem;
    }
  }
  .headerHalf-content {
    @media (max-width: 450) {
      top: 10%;
    }
  }
  
  //overlap
  .overlap-boxx{
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
    background-color: white;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: none;
    align-self: center;
    box-shadow: 0 1.5px 18.5px 0 #00000029;
  }
  .overlap-box-industry {
    display: flex;
    margin-top: 200px;
    flex-direction: column;
    width: 90%;
    height: 100%;
    background-color: white;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: none;
    align-self: center;
    box-shadow: 0 1.5px 18.5px 0 #00000029;
    &-1 {
      padding-bottom: 100px;
      margin-top: 50px;
      @media (max-width: 600px) {
        padding-bottom: 20px;
      }
    }
    .box-title {
      align-self: center;
      text-align: center;
      // text-transform: uppercase;
      margin-top: 5%;
      font-size: 2rem;
      font-weight: 400;
      line-height: 1.53;
      font-weight: bold;
      margin-left: 5%;
      margin-right: 5%;
      @media (max-width: 600px) {
        font-size: 1.2rem;
      }
    }
  }
  
  .industries-text {
    // text-align: left;
    // font-size: 1rem;
    // font-weight: 400;
    // opacity: 0.9;
    // margin: 0 3em;

    text-align: left;
    color: #323132;
    font-weight: 400;
    font-size: 1rem;
    margin: 0 3em;
    opacity: 0.8;
    padding: 3% 3% 2% 3%;
  }


  .platform-content {
    margin: 2% 10% 3% 10%;
    text-align: center;
  }
  
  .cards-div-platform {
    display: grid;
    height: auto;
    grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
    grid-gap: 3em 3em;
    margin-top: 3%;
    align-self: stretch;
  }

  .medium-card {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
   height: 100%; /* Ensure all cards take full height */
  // padding: 0.6em; /* Add padding for consistent spacing */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add box-shadow for styling */
    border-radius: 5.5px; /* Optional: Add border-radius for styling */
    padding-bottom: 20px;
  }

  .medium-card-desc-platform {
    text-align: center;
    color: #323132;
    font-weight: 400;
    font-size: 0.95rem;
    margin: 3% 5% 5% 5%;
    min-height: 8em;
    opacity: 0.8;
  }

  .medium-card-icon-platform {
    margin: 3em 0em 1em 0em;
    width: 3em;
    height: 3em;
    align-self: center;
    // @media (max-width: 600px) {
    //   width: 12%;
    //   margin-top: 3%;
    // }
  }
  .feature-box-left {
    display: flex;
    margin-top: 100px;
    flex-direction: column;
    width: 65%;
    height: 100%;
    background-color: white;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: none;
    align-self: center;
    box-shadow: 0 1.5px 18.5px 0 #00000029;

    @media (max-width: 600px) {
      padding-bottom: 100px;
    }
    @media (max-width: 750px) {
      padding-bottom: 300px;
    }
  }

  .feature-box-right {
    display: flex;
    margin-top: 100px;
    padding-bottom: 50px;
    flex-direction: column;
    width: 65%;
    height: 100%;
   // background-color: white;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: none;
    align-self: center;
    box-shadow: 0 1.5px 18.5px 0 #00000029;
  
    @media (max-width: 600px) {
      padding-bottom: 100px;
    }
    @media (max-width: 750px) {
      padding-bottom: 300px;
    }
  }

  .feature-box-right1 {
    margin-top: 100px;
    display: flex;
    margin-top: 200px;
    flex-direction: column;
    width: 90%;
    height: 100%;
    background-color: white;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: none;
    align-self: center;
    box-shadow: 0 1.5px 18.5px 0 #00000029;
  
    @media (max-width: 600px) {
      padding-bottom: 100px;
    }
    @media (max-width: 750px) {
      padding-bottom: 300px;
    }
  }
  .title-feature {
    font-weight: 600;
    font-size: 1.5em;
    opacity: 0.9;
    margin-top: 2em;
  }

//   .overallcontent{
//     margin-bottom: 50px;
//   }
  .title1-feature{
    font-weight: 600;
    font-size: 1.2em;
    opacity: 0.9;
  }
//   .content1-feature {
//     display: grid;
//     grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
//     grid-gap: 5%;
//     text-align: left;
//     width: 90%;
//   }
  .feature-box-content {
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    // grid-gap: 5%;
    text-align: left;
    margin: 3%;
    margin-top: 2em;
    width: 90%;
  }
  .display-flex {
    display: flex;
    margin: 5px;
  }
  
//   .feature-box-image img {
//     width: 25em;
//     height: 20em;
//   }
  
  .feature-box-image img {
    width: 30em;
    height: 28em;
    //margin: 2em;
  }
  @media (max-width: 700px) {
    .feature-box-image img {
      width: 18em;
      height: 10em;
      float: left;
    }
    .feature-box-left,
    .feature-box-right {
      clear: both;
      display: inline-block;
      padding-bottom: 2em;
      padding: 0;
      margin: 2em;
      width: 90%;
    }
    .title-feature {
      font-size: 1em;
      margin: 1em;
    }
    .display-flex {
      display: grid;
      width: 100%;
      padding: 0;
      margin: 0;
    }
  }


  .kys-div {
    display: flex;
    align-self: center;
    margin: 6%;
    @media (max-width: 700px) {
      display: block;
    }
  }
  
  .kys-card {
    // position: relative;
    // background-color: white;
    // align-items: center;
    // justify-content: center;
    //margin-bottom: 30px;
   // height: 250px;
    border-radius: 5.5px;
    -webkit-border-radius: 5.5px;
    -moz-border-radius: 5.5px;
    -ms-border-radius: 5.5px;
    -o-border-radius: 5.5px;
    display: flex;
//     padding: 20px;
//     margin: 20px; /* Adjust padding as needed */
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow for the card */
    @media (max-width: 500px) {
      margin-left: 5%;
      margin-bottom: 30px;
    }
  }
  .kys-card-icon {
     margin-top: 10px;
    //width: 50%;
    align-self: center;
    // @media (max-width: 600px) {
    //   width: 12%;
    //   margin-top: 3%;
    // }
  }
  .kys-card-title {
    // text-align: center;
    // text-transform: uppercase;
    // margin: -15% 7% 0% 0%;
    color: #9a0cec;
    line-height: 15.5px;
    font-weight: bold;
    font-size: 1.1rem;
    opacity: 0.9;
  }
  .kys-card-btn {
    height: 50px;
    width: 100%;
    position: absolute;
    background-color: rgba(239, 239, 239, 0.3);
    text-transform: uppercase;
    border: none;
    border-top: 0.5px solid #707070;
    color: #9a0cec;
    font-weight: bold;
    line-height: 1.5;
    // margin-top: 330px;
  }
  .kys-card-desc {
    text-align: left;
    color: #323132;
    font-weight: 400;
    font-size: 1rem;
    margin-top: 2%;
    margin-right: 9%;
    min-height: 8em;
    // margin-left: 23%;
    opacity: 0.8;
  }
  
  .overlap-box-technoo {
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 100%;
    background-color: white;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: none;
    align-self: center;
    box-shadow: 0 1.5px 18.5px 0 #00000029;
    padding: 5%;
    margin: 10% 10% 0% 10%;
  }


  .techno-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 5% 5% 5%;
  }
  .techno-title {
    color: #323132;
    font-size: 2rem;
    line-height: 1.5;
    text-align: left;
    font-weight: 600;
  }
  .techno-img {
    margin-top: -15%;
    // height: auto;
    // width: 30%;
  }
  .rest-para {
    color: #323132;
    opacity: 0.8;
    font-size: 1rem;
  }
  
  @media only screen and (max-width: 768px) {
    .overlap-box-technoo
    {
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 40%;
    }
    .techno-img {
      margin-top: -48%;
      width: 55%;
  
    }
    .techno-title{
      text-align: center;
      padding-top: 3em;
      padding-bottom: 5%;
      font-size: 1.25em;
    }
  }
  
  @media only screen and (min-width: 769px) {
    .overlap-box-technoo
    {
      flex-wrap: nowrap;
    }
    .techno-img {
      margin-top: -15%;
    }
  }
  
  
  @media (max-width: 500px) {
    .overlap-box-techno
    {
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 40%;
    }
    .techno-img {
      margin-top: -48%;
      width: 55%;
  
    }
    .techno-title{
      text-align: center;
      padding-top: 3em;
      padding-bottom: 5%;
      font-size: 1.25em;
    }
  }
  