$white: #ffffff;
$primary: #9a0cec;
$secondary: #48bfe3;
.requestalepage {
  display: block;
  max-width: 100%;
  overflow: hidden;
  padding: 0 0 0.5px;
  background-color: #fef9ff;
  font-family: Titillium Web;

  .headerHalf-main-features {
    width: 100vw;
    height: 70vh;
    background-size: cover;
    background-position: center center;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10%;
    color: $white;
  }
  .header {
    width: 100vw;
    padding: 9px 22px 121px 44.5px;
    background-image: url("../../assest/23 - Request Sales Information – 1/request sales-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: -28px 500px; */
    background-position-x: 80%;
    position: relative;
    @media (max-width: 1200px) {
      width: 100%;
    }
    @media (max-width: 600px) {
      background-position-x: 70%;
      padding: 20px 0 100px 0;
      .text-style-3 {
        padding: 0 20px 0 20px !important;
      }
    }
  }

  .headerHalf-main-requestsales {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $white;
  }

  .headerHalf-title {
    font-size: 3rem;
    margin: 0;
    line-height: normal;
    font-weight: bold;
  }
  .headerHalf-uris {
    font-size: 1.2rem;
    margin: 0;
    opacity: 0.8;
  }
  .uris-class {
    border: none;
    margin: 0;
  }

  @media (max-width: 800px) {
    .headerHalf-main > div {
      position: absolute;
      top: 17%;
    }

    .headerHalf-main {
      padding-top: 60%;
      height: 0px;
    }

    p.headerHalf-title {
      font-size: 1.8rem;
    }
  }
  .headerHalf-content {
    @media (max-width: 450) {
      top: 10%;
    }
  }

  .card {
    font-family: Rubik;
  }

  .text-primary {
    color: $primary !important;
  }

  .font-rubik {
    font-family: Rubik;
  }

  .form-sales {
    .form-group {
      position: relative;
    }
    .form-control {
      background: rgba($white, 0.3);
      border-color: $white;
      outline: none !important;
      box-shadow: none !important;
    }
    .form-label {
      font-family: Rubik;
      color: #fff;
      font-size: 16px;
    }
    .invalid-tooltip {
      left: auto;
      right: 0;
    }
  }
  .btn-primary {
    align-self: center;
    border: none;
    padding: 15px 30px;
    text-transform: uppercase;
    background: linear-gradient(91deg, $primary 0%, $secondary 100%);
    color: white;
    margin-top: 4%;
    letter-spacing: 0.36px;
    font-weight: bold;
    transition: all 600ms ease;
    &:hover {
      background: linear-gradient(91deg, $secondary 0%, $primary 100%);
      transition: all 600ms ease;
    }
  }
}
