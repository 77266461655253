.tco-main {
  display: flex;
  flex-direction: row;
  width: 90%;
  padding: 5% 2% 0 2%;
  align-self: center;
  box-shadow: 0 20px 18.5px 0 rgba(105, 48, 195, 0.16);
  background-color: #fff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  margin: -123px 2% 0 5%;
  z-index: 1;
}

.tco-main1 {
  display: flex;
  flex-direction: row;
  width: 90%;
  padding: 5% 2% 0 2%;
  align-self: center;
  box-shadow: 0 1.5px 18.5px 0 rgba(105, 48, 195, 0.16);
  background-color: #fff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  margin: -7% 2% 0 5%;
  z-index: 2;
}
.tco-content {
  width: 70%;
  text-align: left;
  color: #323132;
  opacity: 0.9;
  &__title {
    font-size: 2rem;
    font-weight: 600;
    width: 60%;
    line-height: 1.5;
    &-1 {
      margin-top: 10%;
    }
  }
  &__desc {
    font-family: Rubik;
    width: 70%;
    font-size: 0.9rem;
    line-height: 1.66;
  }
  &__img {
    width: 90%;
    height: 40%;
    margin-top: 2%;
  }
}
.calc {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 3%;
  border-radius: 2.5px;
  border: solid 0.5px #e8c1ff;
  background-color: #fef9ff;
  margin-bottom: 2%;
  &-title {
    align-self: center;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1;
    color: #9a0cec;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 4%;
  }
  &-blackBox {
    // height: 50px;
    align-self: center;
    background-color: #323132;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    padding: 5px 10px;
    &__para {
      font-weight: 600;
      margin-bottom: 0;
      font-size: 0.9rem;
      color: #fff;
      line-height: 1.4;
      text-align: left;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }

  .calc-flex {
    .footer-btn_1 {
      width: 100%;
      height: 60px;
    }
  }
}
.calc-flex_div {
  display: flex;
  flex-direction: row;
  padding: 3%;
  border-bottom: solid 0.5px #f1d9ff;
}
.calc-flex__para {
  font-size: 0.8rem;
  color: #323132;
  line-height: 1.43;
  align-self: flex-start;
  font-family: Rubik;
  font-weight: 600;
  width: 65%;
  line-height: 1.43;
  opacity: 0.9;
}
.calc-flex__input {
  width: 110px;
  margin-left: 5%;
  border-radius: 2.5px;
  border: solid 0.8px #e8c1ff;
  height: 30px;
}

//tco
.headerHalf-main-tco {
  width: 100vw;
  height: 70vh;
  background-image: url('../../../assest/header-img/TCO Calculater.jpg');
  background-size: cover;
  background-position: center center;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10%;
  color: white;
}
.headerHalf-title {
  font-size: 3rem;
  margin: 0;
  line-height: 2.34;
  font-weight: bold;
}
.headerHalf-uris {
  font-size: 1.2rem;
  margin: 0;
  opacity: 0.8;
}
.uris-class {
  border-bottom: 1px solid white;
  margin: 0;
}

@media (max-width: 800px) {
  .headerHalf-main > div {
    position: absolute;
    top: 17%;
  }

  .headerHalf-main {
    padding-top: 60%;
    height: 0px;
  }

  p.headerHalf-title {
    font-size: 1.8rem;
  }
}
.headerHalf-content {
  @media (max-width: 450) {
    top: 10%;
  }
}

// UPDATED CODE
@media only screen and (max-width: 768px) {
  .tco-main {
    flex-direction: column-reverse;
    margin: -10px 2% 0 5%;
    padding: 20px 0;
    .tco-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 20px;
      .tco-content__title {
        width: 100%;
        text-align: center;
      }
      .tco-content__desc {
        width: 100%;
        text-align: center;
      }
      .tco-content__img {
        width: 100%;
      }
    }
    .calc {
      margin: 0 20px;
    }
  }
  .tco-main1 {
    .tco-content {
      width: 100%;
      .tco-content__title {
        width: 100%;
        text-align: center;
      }
      .tco-content__desc {
        width: 100%;
        text-align: center;
      }
    }
  }
}

@media only screen and (min-width: 769px) {
}
