.fixed-width {
  margin: 0px 9%;
  padding: 2% 2%;
  width: 80%;
}
.solution-platform {
  display: flex;
  flex-direction: column;
}
.overlap-box-product-content {
  align-self: center;
  text-align: center;
}
.overlap-box-product-content_text {
  margin: -1% 5% 3% 5%;
  padding: 0 5% 0 5%;
  font-size: 1rem;
  font-weight: 400;
  opacity: 0.9;
  @media (max-width: 900px) {
    font-size: 0.9rem;
  }
  @media (max-width: 600px) {
    font-size: 0.7rem;
  }
}
.to-hold-vedio {
  margin-top: 80px;
  height: 800px;
  padding-left: 10%;
  @media (max-width: 800px) {
    padding-left: 0;
    height: 350px;
  }
  @media (max-width: 500px) {
    padding-left: 0;
    height: 300px;
  }
}
.player-class-1 {
  width: 100%;
  @media (max-width: 600px) {
    height: 250px;
  }
  @media (max-width: 500px) {
    height: 250px;
  }
  @media (max-width: 1200px) {
    height: 250px;
  }
}
.header-over {
  width: 85vw;
  margin: 5% 7.5% 0 7.5%;
  // height: 30vh;
  border-radius: 2px;
  background-image: url("../../../assest/Home/Group\ 741.png");
  background-size: 100%;

  .overlap-content {
    padding-bottom: 30px;
  }
  @media (max-width: 500px) {
    // height: 200px;
  }
}
.grid-product-component {
  display: grid;
  margin-top: 5%;
  height: 70%;
  align-self: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 4%;
  margin-bottom: 2%;
  margin-left: 5%;
  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}
.invesible-card {
  width: 80%;
  display: grid;
  height: 80%;
  align-self: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10%;
  margin: 5% 10% 5% 10%;
  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 5%;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-gap: 1%;
  }
}
.invesible-card-compo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5% 5% 5% 5%;
  text-align: center;
  margin: 0;
  @media (max-width: 600) {
    padding: 1%;
  }
}
.invesibel-card-icon {
  width: 50%;
  @media (max-width: 600) {
    width: 25%;
  }
}
.invesible-card-title {
  margin: 5% 0 0 0;
  color: #9a0cec;
  letter-spacing: 0.5px;
  line-height: 1.55;
  font-size: 1.2rem;
  font-weight: 600;
  @media (max-width: 600) {
    margin-top: 2%;
    font-size: 0.9rem;
  }
}
.invesible-card-desc {
  margin: 0;
  @media (max-width: 600) {
    margin-top: 1%;
    font-size: 0.7rem;
    opacity: 0.9;
  }
}
.extra-large-card {
  width: 110%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to bottom, #9a0cec, #48bfe3);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.large-card-div-product {
  width: 60%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15%;
  margin-top: 5%;
  align-self: center;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-gap: 5%;
  }
}
.extra-large-icon {
  margin-top: 10%;
  width: 25%;
}
.extra-large-card-desc {
  font-family: Rubik;
  margin: 10% 10% 15% 10%;
  text-align: center;
  color: white;
  font-size: 1rem;
  opacity: 0.9;
  font-weight: 300;
  line-height: 1.5;
}
.extra-large-card-button {
  width: 35%;
  height: 20%;
  border: none;
  background-color: white;
  margin-bottom: 10%;
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 1.54;
  opacity: 0.9;
}
.to-push-behind {
  position: absolute;
  z-index: -1;
}
.product-page {
  margin-top: 5%;
  margin-bottom: 5%;
}
.large-container {
  margin-top: -800px;
}
.headerHalf-main-product {
  width: 100vw;
  height: 60vh;
  background-image: url("../../../assest/header-img/product-header-background.png");
  background-size: cover;
  background-position: top center;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10%;
  color: white;
}
.headerHalf-title {
  font-size: 3rem;
  margin: 0;
  line-height: 2.34;
  font-weight: bold;
}
.headerHalf-uris {
  font-size: 1.2rem;
  margin: 0;
  opacity: 0.8;
}
.uris-class {
  border-bottom: 1px solid white;
  margin: 0;
}

@media (max-width: 800px) {
  .headerHalf-main > div {
    position: absolute;
    top: 17%;
  }

  .headerHalf-main {
    padding-top: 60%;
    height: 0px;
  }

  p.headerHalf-title {
    font-size: 1.8rem;
  }
}
.headerHalf-content {
  @media (max-width: 450) {
    top: 10%;
  }
}

// new updates
.image-content-card {
  margin-top: 5em;
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  &.text-title-content-highlight {
    margin-top: 40px;
    background-color: transparent;
  }
  h2 {
    font-size: 3em;
    color: #333;
    font-weight: bold;
    @media (max-width: 500px) {
      font-size: 1.6em;
    }
  }
  .content {
    .img {
      margin-top: 5em;
      padding-right: 40px;
    }
    .sub-title {
      font-size: 1.5em;
      color: #333;
      font-weight: bold;
      line-height: 1.2em;
      margin-bottom: 20px;
      margin-top: 2em;
      @media (max-width: 500px) {
        font-size: 1.2em;
      }
    }

    .text {
      .content-text {
        p {
          font-size: 1.1em;
          @media (max-width: 500px) {
            font-size: 0.8em;
          }
        }
      }
    }
    .highlight {
      font-size: 1.1em;
      margin-top: 15px;
      color: #7926bf;
      font-weight: 600;
      @media (max-width: 500px) {
        font-size: 0.9em;
      }
    }
  }

  .sub-title.text-title-content-highlight {
    margin: 20px 0px;
  }

  .highlight.text-title-content-highlight {
    margin: 25px 0px;
  }
  .highlight.no-highlight.text-title-content-highlight {
    margin: 40px 0px;
  }

  .highlight.m-width {
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
  }

  .ul-container {
    padding-left: 25px;
  }

  .ul-container.text-title-content-highlight {
    margin-top: 30px;
    li {
      // margin: 20px 0px;
    }
  }
}

.text-title-content-highlight {
  font-size: 1.25em;
  font-weight: 600;
  @media (max-width: 500px) {
    font-size: 1em;
  }
}

.lead {
  font-size: 1em !important;
  @media (max-width: 500px) {
    font-size: 0.75em !important;
  }
}