$bright-magenta: #ee00ff;
$azure: #13b9eb;
.footer-main {
  display: flex;
  flex-direction: column;
}
.footer-sec {
  display: flex;
  flex-direction: column;
  background-image: url("../../../assest/Home/network-back_540.png");
  width: 100vw;
  // height: 80vh;
  padding: 3% 3% 8%; /// Rajin
  @media (max-width: 700px) {
    height: auto;
    padding: 20px 0px 20px 0px;
  }
}
.get-started {
  align-self: center;
  font-size: 2.5rem;
  margin: 3% 0 2% 0;
  text-transform: capitalize;
  text-align: center;
  @media (max-width: 900px) {
    font-size: 2.5rem;
  }
  @media (max-width: 600px) {
    font-size: 2rem !important;
  }
}
.earthid-footer-desc {
  align-self: center;
  max-width: 700px;
  width: 60%;
  font-size: 1.2rem;
  text-align: center;
  opacity: 0.8;
  font-weight: 600;
  @media (max-width: 700px) {
    width: 80%;
    font-size: 0.8em !important;
  }
}
.footer-btn_1 {
  align-self: center;
  border: none;
  // width: 15%;
  // height: 7%;
  padding: 15px 30px;
  // text-transform: uppercase;
  background: linear-gradient(91deg, #9a0cec 0%, #48bfe3 100%);
  color: white;
  margin-top: 4%;
  border-radius: 5px;
  // line-height: 1.5;
  letter-spacing: 0.36px;
  font-weight: bold;
  @media (max-width: 900px) {
    width: 25em;
  }
  @media (max-width: 600px) {
    width: 20em;
    padding: 10px;
  }
  a {
    p {
      font-size: 18px;
    }
  }
}
.footer-content {
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, #9a0cec, #48bfe3 84%);
  color: white;
  height: 100%;
}
.certification-section {
  display: flex;
  justify-content: space-between!important;
  align-items: center;
  margin: 2rem auto 0rem auto;
  padding: 1rem 10rem;
  //background-color: #f7f7f7; // optional: set background color to make section stand out
  
  /* Flex layout for better control of spacing */
  flex-wrap: wrap;
  gap: 1rem;

  @media (max-width: 900px) {
    justify-content: center!important;
  }

  @media (max-width: 600px) {
    padding: 1rem;
    justify-content: center!important;
  }

  img.certificationsImg {
    height: 120px; /* Set uniform height for all images */
    width: 190px; /* Keep the width flexible to maintain aspect ratio */
    //max-width: 180px; /* Ensure the aspect ratio is maintained */
   // object-fit: contain; /* Ensure the images are scaled properly inside the container */
    transition: transform 0.3s ease-in-out;

    /* Add a hover effect */
    &:hover {
      transform: scale(1.05); /* Slight zoom effect on hover */
    }

    /* Adjustments for smaller devices */
    @media (max-width: 900px) {
      width: 22%;
    }

    @media (max-width: 600px) {
      width: 40%;
    }

    @media (max-width: 400px) {
      width: 60%;
    }
  }

  img.certificationsImg1 {
    height: 120px; /* Set uniform height for all images */
    width: 120px; /* Keep the width flexible to maintain aspect ratio */
    //max-width: 180px; /* Ensure the aspect ratio is maintained */
   // object-fit: contain; /* Ensure the images are scaled properly inside the container */
    transition: transform 0.3s ease-in-out;

    /* Add a hover effect */
    &:hover {
      transform: scale(1.05); /* Slight zoom effect on hover */
    }

    /* Adjustments for smaller devices */
    @media (max-width: 900px) {
      width: 22%;
    }

    @media (max-width: 600px) {
      width: 40%;
    }

    @media (max-width: 400px) {
      width: 60%;
    }
  }
  
}

.subscribe-container {
  width: 30em;
  margin: 5% 0% 5% -3%;
 // padding-left: 1em;
 @media (max-width: 750px) {
  margin: 23% 0% 5% 3%!important;
}
  .newsletter-subs {
    text-align: left;
    // text-transform: uppercase;
    font-weight: 600;
    line-height: 2.15;
    font-size: 1.1rem;
    @media (max-width: 750px) {
      text-align: center;
      font-size: 2rem;
      line-height: 1.2;
    }
  }
  .subscribe-form {
    text-align: center;
    // text-transform: uppercase;
    line-height: 2.15;
    font-size: 0.9rem;
    .calc-flex__input {
      width: 75%;
      padding: 20px;
      margin-right: 10px;
      border: 2px solid #ddd;
      background-color: transparent;
      color: #fff;
      margin-left: 0px;
      &::placeholder {
        color: #fff;
        opacity: 1; /* Firefox */
      }
      &:hover,
      &:focus {
        outline: 0;
      }
    }
    button {
      border: 0px;
      outline: 0;
      flex: 1;
      background-color: #fff;
      a p {
        color: #333;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 16px;
        @media (max-width: 750px) {
          padding: 0 5px;
          font-size: 14px;
        }
      }
    }
  }
 
}

.rest-footer-info {
  display: grid;
  margin: 5% 10% 5% 10%;
  grid-template-columns: 1.2fr 0.5fr 1.2fr;
  grid-gap: 10%;
  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    display: block;
    text-align: center;
  }
}
.content-footer-info {
  display: flex;
  flex-direction: column;
  @media (max-width: 500px) {
    margin-top: 2em;
  }
}
.logo-earthId {
  width: 8em;
  margin-left: -0.6em;
  @media (max-width: 600px) {
    margin: auto;
  }
}
.earthid-desc {
  margin-top: 1rem;
  line-height: 1.32;
  font-size: 1rem;
}
.info-title {
  // text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 5%;
  @media (max-width: 500px) {
    font-size: 2em;
  }
}
.info-links {
  text-decoration: none !important;
  color: #fff !important;
  opacity: 0.8;
  margin-top: -1%;
  @media (max-width: 500px) {
    font-size: 1rem;
  }
}
.div-social {
  display: flex;
  width: 100%;
  @media (max-width: 600px) {
    // text-align: center;
    // display: table;
    // margin: auto;
    justify-content: center;
  }
}
.img-link-media {
  width: 100%;
}
.link-media {
  margin-right: 5%;
}
.btn-title {
  margin-bottom: 1%;
  font-size: 0.9rem;
  @media (max-width: 900px) {
    font-size: 0.8rem;
  }
  @media (max-width: 600px) {
    font-size: 0.6rem;
  }
}
.links,
.links:focus {
  text-decoration: none;
  color: inherit;
}
.footer-btn_1 .links:hover{
  color: #fff;
}

.bottom-bar-line {
  height: 0;
  /* margin: 33.2px 0 0; */
  opacity: 0.41;
  border-top: solid 0.03em var(--white);
}

.bottom-rights-box {
  min-height: 2em;
  border-top: 1px solid rgba(248, 251, 252, 0.2);
}

.bottom-rights-text {
  font-family: Rubik;
  font-size: 0.6em;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.05em;
  opacity: 0.41;
  text-align: center;
  padding-top: 1em;
}

.link-media-resize {
  width: 30px;
}

.subscribe-form {
  width: 100%;
}

@media (max-width: 500px) {
  .subscribe-container {
    width: 95%;
    font-size: 0.8em;
    margin-top: 10%;
  }
}

.subscribe-form button {
  border-radius: 3px;
  font-size: 0.5em;
}


.right-section {
  
  //gap: 1.5rem; // Adjust the gap between rows
  //padding: 1rem;
  flex: 1;
  justify-content: center;
  
  .right1 {
    display: flex;
  flex-direction: row;
 
 // justify-content: space-between;
    gap: 10rem;
    @media (max-width: 900px) {
      flex-direction: column;
      gap: 2rem;
    }
  }
}








