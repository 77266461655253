.faceId-content {
  display: flex;
  flex-direction: column;
  margin: 3rem 2rem 2rem 2rem;
  color: #ffffff;

  @media (max-width: 400px) {
    padding-bottom: 137px;
  }
  @media (max-width: 680px) {
    padding-bottom: 300px;
  }
}
.faceId-content_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
  width: 70%;
  align-self: center;
  margin-top: 3rem;
  @media (max-width: 400px) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  @media (max-width: 650px) {
    margin-top: 1.3rem;
    margin-bottom: 1.3rem;
  }
}
.faceId-content_text-1 {
  font-family: rubik;
  font-size: 2.5rem;
  // text-transform: uppercase;
  font-weight: 500;
  margin: 0;
}
.faceId-content_text-2 {
  font-weight: 530;
  font-size: 2rem;
  margin: 1rem 0 1rem 0;
  line-height: 1.33;
  text-align: center;
  @media (max-width: 400px) {
    font-size: 1.2rem;
  }
  @media (max-width: 650px) {
    font-size: 1.5rem;
  }
}
.faceId-content_text-3 {
  font-size: 1.1rem;
  margin-top: 1%;
  width: 95%;
  text-align: center;
  @media (max-width: 400px) {
    margin-top: 1%;
  }
  @media (max-width: 650px) {
    margin-top: 1.3%;
  }
}
// .faceId-content_text-3-spaning {
//   padding-left: 10%;
// }
.faceId-content_dynamic {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0% 5% 10%;
  grid-gap: 5%;
}
.faceId-content_dynamic-1 {
  display: flex;
  flex-direction: column;
  padding: 5% 5% 5% 5%;
}
.faceId-content_dynamic-1-title {
  font-weight: 600;
  font-size: 2.4rem!important;
  // text-transform: uppercase;
  margin-bottom: 0;
  @media (max-width: 400px) {
    font-size: 1rem;
  }
  @media (max-width: 650px) {
    font-size: 1.1rem;
  }
}
.faceId-content_dynamic-1-desc {
  font-weight: 500;
  font-size: 1.4rem!important;
  width: 95%;
}
.faceId-content_dynamic-1-img {
  width: 80%;
  margin-top: -4rem;
  margin-left: -2rem;
}
.faceId-content_text-3-spaning {
  margin-top: 1%;
  font-size: 1.1rem;
}
//put the code here by mistake
.faceId-dyanmic-img-div {
  position: relative;
  height: 400px;
  margin: auto;
  width: 450px;

  @media (max-width: 950px) {
    height: 300px;
    width: 350px;
  }
  @media (max-width: 450px) {
    height: 280px;
    width: 330px;
  }
  // @media (max-width: 400px) {
  //   width: 100%;

  // }
  // @media (max-width: 1200px) {
  //   width: 90%;
  // }
  
  // -webkit-animation: fadeinout 4s linear forwards;
  // animation: fadeinout 4s linear forwards;
  // animation-iteration-count: infinite;
  // animation-timing-function: linear;
  // -webkit-animation-iteration-count: infinite;
  // -webkit-animation-timing-function: linear;
}
.faceId-content_dynamic-2-img{
  height: 400px;
  margin: auto;
  width: 450px;

  @media (max-width: 950px) {
    height: 300px;
    width: 350px;
  }
  @media (max-width: 450px) {
    height: 280px;
    width: 330px;
  }
}
// .faceId-dyanmic-img-div:hover {
//   -webkit-animation-play-state: paused;
//   -moz-animation-play-state: paused;
//   -o-animation-play-state: paused;
//   animation-play-state: paused;
//   cursor: pointer;
// }

// @-webkit-keyframes fadeinout {
//   0%,
//   100% {
//     background-size: 100% 100%;
//   }
//   50% {
//     background-size: 97% 97%;
//   }
// }

// @keyframes fadeinout {
//   0%,
//   100% {
//     background-size: 100% 100%;
//   }
//   50% {
//     background-size: 97% 97%;
//   }
// }
// .faceId-content-text-block {
//   top: 50%;
//   padding-top: 25%;
// }

.dynamic-btn {
  cursor: pointer;
  outline: none;
  border: none;
  position: absolute;
  color: transparent;
  background-position: center;
  height: 85px;
  width: 85px;
  background-size: 30px 30px;
  background-color: transparent;
  background-repeat: no-repeat;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;

  // @media (max-width: 1250px) {
  //   background-size: 90px 90px; 
  // }
  // @media (max-width: 1200px) {
  //   background-size: 80px 80px; 
  // }

  &_1 {
    top: 0%;
    left: 17%;
   }
  &_2 {
    top: 0%;
    left: 62%;
  }
  &_3 {
    top: 38%;
    left: 80%;
  }
  &_4 {
    top: 76%;
    left: 62%;
  }
  &_5 {
    top: 76%;
    left: 17%;
  }
  &_6 {
    top: 38%;
    left: 0%;
  }
  &_7 {
    top: 22%;
    left: 40%;
    // background-size: 116px 129px;
    // background-image: url('../../../assest/Home/decentralized_icon.png');
    // @media (max-width: 950px) {
    //   top: 21%;
    //   left: 32%;
    //   background-size: 106px 119px;  
    // }
    // @media (max-width: 1200px) {
    //     top: 14%;
    //     left: 23%;
    //     background-size: 96px 109px;  
    //   }
  }
  &_8 {
    top: 48%;
    left: 53%;
    // background-size: 106px 144px;
    // background-image: url('../../../assest/Home/security_icon.png');
    // @media (max-width: 950px) {
    //   background-size: 96px 134px;  
    //   top: 38%;
    // left: 47%;
    // }
    // @media (max-width: 1200px) {
    //   background-size: 86px 124px;  
    //   top: 31%;
    // left: 38%;
    // }
    // @media (max-width: 600px) {  
    // top: 33%;
    // left: 38%;
    // }
  }
  &_9 {
    top: 48%;
    left: 27%;
    // background-size: 155px 105px;
    // background-image: url('../../../assest/Home/digital_icon.png');
    // @media (max-width: 950px) {
    //   background-size: 142px 98px;  
    //   top: 42.5%;
    // left: 21.5%;
    // }
  //   @media (max-width: 1200px) {
  //     background-size: 130px 88px;  
  //     top: 35.5%;
  //   left: 14.5%;
  //   }
  //   @media (max-width: 600px) {  
  //     top: 37.5%;
  //     left: 14.5%;
  //     }
  // }
  // &_9:hover {
  //   background-image: url('../../../assest/Home/digital_icon_hover.png');
  // }

  }


}


.dynamic-btn:focus {
  outline: none;
}
.faceId-content_dynamic-1-desc {
  margin-top: 3%;
  font-size: 1.2rem;
  color: #fff;
  opacity: 0.9;
}
// @media (max-width: 1111px) {
//   .dyamic-btn {
//     width: 6%;
//     height: 9%;
//   }
// }
// @media (max-width: 950px) {
//   .dyamic-btn {
//     width: 6%;
//     height: 8%;
//   }
// }
// @media (max-width: 900px) {
//   .dyamic-btn {
//     width: 6%;
//     height: 7%;
//   }
// }
// @media (max-width: 850px) {
//   .dyamic-btn {
//     width: 5%;
//     height: 6%;
//   }
// }


.read-more-link {
  display: inline-block;
  margin-top: 10px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    text-decoration: none;
    color: #fff;
  }
}