.ssi-platform {
  display: flex;
  flex-direction: column;
}
.overlap-box {
  margin-top: -10%;
  padding-bottom: 10%;
}
.ssi-block {
  opacity: 0.8;
  padding: 0 5% 0 5%;
  margin: -1% 5% 3% 5%;
}
.ssi-sub-title {
  font-weight: 700;
  font-size: 1.7rem;
  color: blue;
}
.ssi-text {
  text-align: left;
  font-size: 1rem;
  font-weight: 400;
  opacity: 0.9;
  margin: 0;
  margin-top: 1em;
}
ul.ssi-list {
  font-size: 1rem;
  font-weight: 400;
  padding-left: 2em;
  margin-top: 2em;
}

.cards-div-ssi {
  display: grid;
  height: 100%;
  width: 100%;
  align-self: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 30px 30px;
  // margin-bottom: 5%;
  padding-left: 5%;
  padding-right: 3%;
  padding-top: 3%;
  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}
.small-card {
  position: relative;
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5.5px;
  -webkit-border-radius: 5.5px;
  -moz-border-radius: 5.5px;
  -ms-border-radius: 5.5px;
  -o-border-radius: 5.5px;
  border: solid 0.5px #e2e2e2;
  margin-bottom: 20%;
  box-shadow: 0 1.5px 25px 0 #6930c31a;
}

//ssi
.headerHalf-main-ssi {
  width: 100vw;
  height: 70vh;
  background-image: url("../../assest/header-img/SSI2.jpg");
  background-size: cover;
  background-position: center center;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10%;
  color: white;
}
.headerHalf-title {
  font-size: 3rem;
  margin: 0;
  line-height: 2.34;
  font-weight: bold;
}
.headerHalf-uris {
  font-size: 1.2rem;
  margin: 0;
  opacity: 0.8;
}
.uris-class {
  border-bottom: 1px solid white;
  margin: 0;
}

@media (max-width: 800px) {
  .headerHalf-main > div {
    position: absolute;
    top: 17%;
  }

  .headerHalf-main {
    padding-top: 60%;
    height: 0px;
  }

  p.headerHalf-title {
    font-size: 1.8rem;
  }
}
.headerHalf-content {
  @media (max-width: 450) {
    top: 10%;
  }
}


.img-for-ssi {
  width: 100%;
  margin: 0;
}

.image-ssi-container {
  margin: 3em auto;
  width: 100%;
  @media (max-width:500px) {
    width: 100%;
  }
}

.small-text {
  font-size: 1.2rem;
}