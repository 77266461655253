.header-overlap-earthid {
  width: 85vw;
  margin: 5% 7.5% 0 7.5%;
  // height: 30vh;
  border-radius: 2px;
  background-image: url("../../../assest/Home/Group\ 741.png");
  background-size: 100%;
  padding: 15px 0 30px;
}
.Box-Earth-id {
  width: 90%;
  align-self: center;
  align-items: center;
  text-align: center;
  color: #323132;
  margin: 5%;
  border-radius: 5px;
  box-shadow: 0 1.5px 8px 0 #6930c31a;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  padding-bottom: 4%;
  background: #fff;
  @media (max-width: 600px) {
    // padding-bottom: 100px;
  }
  @media (max-width: 750px) {
    // padding-bottom: 300px;
  }
}
.title-earth-id {
  font-weight: 600;
  font-size: 2rem;
  opacity: 0.9;
  margin: 5%;
}
.first-flex-div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 2em;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  @media (max-width: 600px) {
    display: block;
    margin: 0 auto;
    margin-top: 8em;
    // padding-bottom: 100px;
  }

}
.text-content-earth {
  @media (max-width: 900px) {
    margin-left: 3%;
    margin-right: 3%;
  }
}
.text-content-sub-title {
  margin: 2em 0 1em 0;
  font-weight: 600;
}
//about
.headerHalf-main-about {
  width: 100vw;
  height: 70vh;
  background-image: url("../../../assest/header-img/About.jpg");
  background-size: cover;
  background-position: center center;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10%;
  color: white;
}
.headerHalf-title {
  font-size: 3rem;
  margin: 0;
  line-height: 2.34;
  font-weight: bold;
}
.headerHalf-uris {
  font-size: 1.2rem;
  margin: 0;
  opacity: 0.8;
}
.uris-class {
  border-bottom: 1px solid white;
  margin: 0;
}

@media (max-width: 800px) {
  .headerHalf-main-about > div {
    position: absolute;
    top: 10%;
  }

  .headerHalf-main-about {
    padding-top: 60%;
    height: 0px;
  }

  p.headerHalf-title {
    font-size: 1.8rem;
  }
}
.headerHalf-content {
  @media (max-width: 450) {
    top: 10%;
  }
}

.img-for-company {
  /* width: auto; */
  margin: 5em 0em 5em 2em;
  height: 20em;
  @media (max-width: 500px) {
    margin: 0 auto;
    height: 12em;
  }
}

.link-media {
  margin-right: 5%;
}

.link-media-resize {
  width: 30px;
}

.cimg-link-media {
  width: 15%;
}

.leadership-profile {
  width: 100%;
  display: flex;
  margin: 2rem 0;
  flex-direction: column;
  background: white;
  padding: 2rem 2rem 2rem 2rem;
  border-radius: 5.5px;
  box-shadow: 0 1.5px 25px 0 rgba(105, 48, 195, 0.1);
  align-items: center;
  position:relative;
  @media (max-width:600px) {
    display: block;
    margin: 0 0 8em 0;
  }
}

.profile-icon {
  margin-top: -6rem;
  width: 100%;
  align-self: center;
  border: 4px solid #5b47a5;
  border-radius: 50%;
  height: 15em;
}

.profile-title {
  font-size: 1.15rem;
  text-align: center;
  // text-transform: uppercase;
  margin: 5% 5% 5% 5%;
  color: #323132;
  line-height: 1.33;
  font-weight: bold;
  opacity: 0.9;
}

.profile-desc {
  text-align: center;
  color: #7926bf;
  font-weight: bold;
  font-size: 1.1rem;
  margin: 3% 5% 5% 5%;
  opacity: 1;
}

.about-company {
  display:flex !important;
  padding: 3% 5%;
}

p.profile-desc-cont {
  font-size: 0.8em;
  text-align: justify;
  margin-bottom: 5em;
}

.text-content-partner{
  margin-top: 50px;
}

.linkedInIcon {
  position:absolute;
  bottom: 2em;
}