.solution-platform {
  display: flex;
  flex-direction: column;
}
.cards-div {
  display: grid;
  height: auto;
  grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
  grid-gap:50px 50px;
  margin: 5%;
  width: 80%;
  align-self: center;
  @media (max-width: 675px) {
    grid-gap: 20px 20px;
  }
  min-width: 340px;
}
.medium-card {
  position: relative;
  background-color: white;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5.5px;
  -webkit-border-radius: 5.5px;
  -moz-border-radius: 5.5px;
  -ms-border-radius: 5.5px;
  -o-border-radius: 5.5px;
  border: solid 0.5px #e2e2e2;
  padding-bottom: 50px;
  box-shadow: 0 1.5px 25px 0 #6930c31a;
  @media (max-width: 500px) {
    margin-left: 5%;
  }
}
.medium-card-icon {
  margin-top: 60px;
  width: 28%;
  align-self: center;
  // @media (max-width: 600px) {
  //   width: 12%;
  //   margin-top: 3%;
  // }
}
.medium-card-title {
  text-align: center;
  // text-transform: uppercase;
  margin: 5% 5% 5% 5%;
  color: #323132;
  line-height: 1.33;
  font-weight: bold;
  opacity: 0.9;
}
.medium-card-btn {
  height: 50px;
  width: 100%;
  position: absolute;
  background-color: rgba(239, 239, 239, 0.3);
  text-transform: uppercase;
  border: none;
  border-top: 0.5px solid #707070;
  color: #9a0cec;
  font-weight: bold;
  line-height: 1.5;
  // margin-top: 330px;
}
.medium-card-desc {
  text-align: justify;
  color: #323132;
  font-weight: 400;
  font-size: 0.95rem;
  margin: 3% 5% 5% 5%;
  opacity: 0.8;
}
.overlap-box .extend-it {
  padding-bottom: 200px;
}
//for header
.headerHalf-main-use {
  width: 100vw;
  height: 70vh;
  background-image: url('../../../assest/header-img/Use\ Cases.jpg');
  // background-size: cover;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10%;
  color: white;
  background-size: cover;
  background-position: center center;
}
.headerHalf-title {
  font-size: 3rem;
  margin: 0;
  line-height: 2.34;
  font-weight: bold;
}
.headerHalf-uris {
  font-size: 1.2rem;
  margin: 0;
  opacity: 0.8;
}
.uris-class {
  border-bottom: 1px solid white;
  margin: 0;
}

@media (max-width: 800px) {
  .headerHalf-main > div {
    position: absolute;
    top: 17%;
  }

  .headerHalf-main {
    padding-top: 60%;
    height: 0px;
  }

  p.headerHalf-title {
    font-size: 1.8rem;
  }
}
.headerHalf-content {
  @media (max-width: 450) {
    top: 10%;
  }
}

.use-case-footer-text {
  opacity: 0.8;
}

.use-case-footer-title {
  margin-bottom: 0.9em;
}

.use-case-footer {
  margin: 0 10%;
}