.overlap-box-subscribe {
  display: flex;
  flex-direction: row;
  width: 90%;
  height: 100%;
  background-color: white;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: none;
  align-self: center;
  box-shadow: 0 1.5px 18.5px 0 #00000029;
  padding: 5%;
  margin: 10% 10% 10% 10%;
}
.subscribe-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 5% 5% 5%;
}
.subscribe-title {
  color: #323132;
  font-size: 2rem;
  line-height: 1.5;
  text-align: left;
  font-weight: 600;
}
.subscribe-img {
  margin-top: -15%;
}
.rest-para {
  color: #323132;
  opacity: 0.8;
  font-size: 1rem;
}

@media only screen and (max-width: 768px) {
  .overlap-box-subscribe {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40%;
  }
  .subscribe-img {
    margin-top: -48%;
    width: 55%;
  }
  .subscribe-title {
    text-align: center;
    padding-top: 5%;
    padding-bottom: 5%;
  }
}

@media only screen and (min-width: 769px) {
  .overlap-box-subscribe {
    flex-wrap: nowrap;
  }
  .subscribe-img {
    margin-top: -15%;
  }
}
.popupText {
  padding-left: 30%;
  padding-top: 4em;
  font-size: 1.12rem;
  opacity: 0.9;
  width: 95%;
}

.subscriber-dialog {
  background-image: url("../../assest/Home/notification-bg.png");
  background-position: left;
  background-size: cover;
  height: 10em;
  width: 50em;
  background-repeat: no-repeat;
  display: flex;
}

.cancel-btn-sub {
  float: right;
}

.cancel-btn-block {
  float: right;
}

@media (max-width: 500px) {
  .subscriber-dialog {
    background-position: -10em;
    height: 10em;
    width: 100%;
    display: flex;
  }
  .popupText {
    padding-top: 6em;
    font-size: 0.7rem;
    opacity: 0.9;
    width: 95%;
    text-overflow: clip;
  }
  .cancel-img {
    width: 40%;
    /* height: 44%; */
  }
}
.MuiDialogContent-root:first-child {
  padding: 0 !important;
  margin: 0;
}
