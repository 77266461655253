.grid-for-news {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 80%;
  align-self: center;
  grid-gap: 6%;
  height: 100%;
  margin-top: 5%;
  margin-bottom: 5%;
  @media (max-width: 1150px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 726px) {
    grid-template-columns: 1fr;
  }
}
.btn-for-news {
  // width: 20%;
  align-self: center;
  margin-top: 3%;
  height: 2.8rem;
  border: none;
  background-image: linear-gradient(91deg, #9a0cec 0%, #48bfe3 100%);
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.9px;
  line-height: 1.5;
  margin-bottom: 0;
  @media (max-width: 1200px) {
    margin-top: 5%;
  }
  @media (max-width: 900px) {
    margin-top: 8%;
    font-size: 1rem;
  }
  @media (max-width: 730px) {
    margin-top: 150px;
    font-size: 0.8rem;
  }
  @media (max-width: 600px) {
    width: 150px;
  }
}
.title-for-news {
  text-align: left;
  margin: 7% 5% 5% 7%;
  color: #323132;
  font-weight: 600;
  line-height: 1.22;
  font-size: 0.9rem;
  min-height: 3em;
  max-height: 3em;
}
.dateFieldNews {
  font-family: Rubik;
  color: #9a0cec;
  text-align: left;
  font-size: 0.7rem;
  font-weight: 600;
  min-height: 2em;
  margin: 0 10% 3% 7%;
}
overlap-box-news-1 {
  position: relative;
}
.overlap-box-for-news {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  background-color: white;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: none;
  align-self: center;
  box-shadow: 0 1.5px 18.5px 0 #00000029;
  padding-bottom: 10%;
  // margin-top: -5%;
  margin-top: 3em;
}
.box-title-for-news {
  align-self: center;
  text-align: center;
  // text-transform: uppercase;
  margin-top: 3%;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.53;
  font-weight: bold;
  margin-left: 5%;
  margin-right: 5%;
}
.press-div {
  display: flex;
  flex-direction: column;
  margin: 0 5% 0 5%;
  background-color: #fdf9ff;
  border: solid 0.5px #eed2ff;
  border-radius: 5.5px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 5.5px;
  -ms-border-radius: 5.5px;
  -o-border-radius: 5.5px;
  padding: 0 0 5% 3%;
}
.grid-for-news-publishers1 img{
  height: 30em;
  width: auto;
  margin: 30px 10px;
  // background-image: url("../../../assest/16\ -\ About\ -\ Latest\ News/ifta_2019.jpg");
  // background-size: cover;
  // background-position: center center;
  // height: 30em;
  @media (max-width: 850px) {
    
    height: 19em;
  }
}
.grid-for-news-publishers2 img{
  height: 30em;
  width: auto;
  margin: 30px 10px;
  padding-top: 30px;
  // background-image: url("../../../assest/16\ -\ About\ -\ Latest\ News/ifta_2019.jpg");
  // background-size: cover;
  // background-position: center center;
  // height: 30em;
  @media (max-width: 850px) {
    width: 90%;
    height: 19em;
  }
}
.award-image-title{
  text-align: center;
  margin: 20px;
  padding: auto;
  font-size: 1.3rem;
  font-weight: 600;
}
.publisher {
  color: #323132;
  font-size: 0.9em;
  line-height: 1.55;
  text-align: left;
  font-weight: 600;
  margin: 5% 5% 1% 3%;
  min-height: 8em;
}
.anchor-tag-publisher {
  color: #323132;
  margin: 1% 3% 3% 3%;
  font-weight: 600;
}
.dateFieldPublisher {
  font-family: Rubik;
  color: #9a0cec;
  text-align: left;
  font-size: 0.7rem;
  font-weight: 600;
  margin: 0 50% 2% 3%;
  text-decoration: none;
  min-height: 3em;
  &:focus {
    border-bottom: solid 2px #323132;
  }
}

//news
.headerHalf-main-news {
  width: 100vw;
  height: 70vh;
  background-image: url("../../../assest/header-img/Latest News.jpg");
  background-position: center center;
  background-size: cover;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10%;
  color: white;
}
.headerHalf-title {
  font-size: 3rem;
  margin: 0;
  line-height: 2.34;
  font-weight: bold;
}
.headerHalf-uris {
  font-size: 1.2rem;
  margin: 0;
  opacity: 0.8;
}
.uris-class {
  border-bottom: 1px solid white;
  margin: 0;
}

@media (max-width: 800px) {
  .headerHalf-main-news > div {
    position: absolute;
    top: 10%;
  }

  .headerHalf-main-news {
    padding-top: 60%;
    height: 0px;
  }

  p.headerHalf-title {
    font-size: 1.8rem;
  }
}
.headerHalf-content {
  @media (max-width: 450) {
    top: 10%;
  }
}

.slick-slider {
  // max-width: 900px;
  // margin: 0px auto;
  margin-top: 5%;
  display: flex;
  justify-content: space-between;
  .slick-prev:before,
  .slick-next:before {
    color: #000;
  }
  .slick-slide {
    margin-bottom: 10px;
    // width: 22em;
    & > div {
      padding: 0px 20px;
      .blog-div .news-div {
        width: auto;
      }
    }
  }
}
.award-image-title {
  margin: 0 auto;
  @media (max-width: 500px) {
    font-size: 0.55em;
  }
}

.slick-list {
  width: 100%;
}

.img-for-press {
  margin: 1em auto;
}

.news-div {
  position: relative;
  // display: flex;
  flex-direction: column;
  width: 20em !important;
  align-items: center;
  text-align: center;
  border-radius: solid 0.5 #e2e2e2;
  -webkit-border-radius: solid 0.5 #e2e2e2;
  -moz-border-radius: solid 0.5 #e2e2e2;
  -ms-border-radius: solid 0.5 #e2e2e2;
  -o-border-radius: solid 0.5 #e2e2e2;
  box-shadow: 0 1.5px 25px 0 rgba(105, 48, 195, 0.1);
  height: 14.75em;
  margin: 0 auto;
}

.img-for-news {
  width: 100%;
  height: 60%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}
