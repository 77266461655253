.solution-platform {
  display: flex;
  flex-direction: column;
}
.cards-div-industries {
  display: grid;
  height: 90%;
  grid-template-columns: repeat(auto-fit, minmax(18em, 1fr));
  grid-gap: 30px 30px;
  margin: 5%;
  @media (max-width: 600px) {
    align-items: center;
    grid-gap: 20px 20px;
  }
}
.largeind-card {
  // max-width: 300px;
  background-color: white;
  // min-height: 350px;
  // width: 85%;
  padding-top: 3%;
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border: solid 0.5px #e2e2e2;
  box-shadow: 0 1.5px 25px 0 #6930c31a;
  position: relative;
  @media (max-width: 600px) {
   height: 520px;
  }
}
.large-card-img {
  // width: 100%;
  height: 35%;
  padding-top: 10px;
  align-self: center;
  object-fit: cover;
  border-radius: inherit;
}
.large-card-title {
  text-align: center;
  // text-transform: uppercase;
  margin: 6% 5% 2% 5%;
  color: #323132;
  line-height: 1.33;
  font-weight: bold;
  opacity: 0.9;
}
.large-card-btn {
  width: 100%;
  height: 40px;
  // margin-top: 310px;
  bottom: 0;
  position: absolute;
  background-color: rgba(239, 239, 239, 0.3);
  text-transform: uppercase;
  border: none;
  border-top: 0.5px solid #707070;
  color: #9a0cec;
  font-weight: bold;
  line-height: 1.5;
  justify-self: flex-end;
  position: absolute;
}
.large-card-desc {
  text-align: left;
  color: #323132;
  font-weight: 400;
  font-size: 0.85rem;
  margin: 1% 5% 5% 5%;
  opacity: 0.8;
  padding: 3% 3% 2% 3%;
}
.extra-large-card {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to bottom, #9a0cec, #48bfe3);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  max-width: 350px;
  @media (max-width: 500) {
    max-width: 270px;
  }
}
.large-card-div {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
  grid-gap: 3em;
  justify-items: center;
  margin-top: 5%;
  align-self: center;
  @media (max-width: 750px) {
    grid-gap: 20px 20px;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
}
.extra-large-icon {
  margin-top: 10%;
  width: 25%;
}
.extra-large-card-desc {
  font-family: Rubik;
  margin: 10% 10% 15% 10%;
  text-align: center;
  color: white;
  font-size: 1rem;
  opacity: 0.9;
  font-weight: 300;
  line-height: 1.5;
}
.extra-large-card-button {
  width: 35%;
  height: 20%;
  border: none;
  background-color: white;
  margin-bottom: 10%;
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 1.54;
  opacity: 0.9;
}
.industries-text {
  @media (max-width: 600) {
    font-size: 0.8rem;
    font-weight: 500;
    opacity: 0.8;
  }
}
//@at-root
.headerHalf-main-industry {
  width: 100vw;
  height: 70vh;
  background-image: url('../../../assest/header-img/Industries.jpg');
  background-size: cover;
  background-position: center center;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10%;
  color: white;
}
.headerHalf-title {
  font-size: 3rem;
  margin: 0;
  line-height: 2.34;
  font-weight: bold;
}
.headerHalf-uris {
  font-size: 1.2rem;
  margin: 0;
  opacity: 0.8;
}
.uris-class {
  border-bottom: 1px solid white;
  margin: 0;
}

@media (max-width: 800px) {
  .headerHalf-main-industry > div {
    position: absolute;
    top: 17%;
  }

  .headerHalf-main-industry {
    padding-top: 60%;
    height: 0px;
  }

  p.headerHalf-title {
    font-size: 1.8rem;
  }
}
.headerHalf-content {
  @media (max-width: 450) {
    top: 10%;
  }
}

//overlap
.overlap-box-industry {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  background-color: white;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: none;
  align-self: center;
  box-shadow: 0 1.5px 18.5px 0 #00000029;
  &-1 {
    padding-bottom: 100px;
    margin-top: 50px;
    @media (max-width: 600px) {
      padding-bottom: 20px;
    }
  }
  .box-title {
    align-self: center;
    text-align: center;
    // text-transform: uppercase;
    margin-top: 5%;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.53;
    font-weight: bold;
    margin-left: 5%;
    margin-right: 5%;
    @media (max-width: 600px) {
      font-size: 1.2rem;
    }
  }
}

.industries-text {
  text-align: left;
  font-size: 1rem;
  font-weight: 400;
  opacity: 0.9;
  margin: 0 3em;
}