.overlap-box-with-filters {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  background-color: white;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: none;
  align-self: center;
  box-shadow: 0 1.5px 18.5px 0 #00000029;
  padding-bottom: 5%;
  margin-top: -5%;
}
