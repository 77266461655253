.blogs-main{
  background-color: #fef9ff;
}

.overlap-box-privacy {
  display: flex;
  flex-direction: row;
  width: 90%;
  height: 100%;
  background-color: white;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: none;
  align-self: center;
  box-shadow: 0 1.5px 18.5px 0 #00000029;
  padding: 5%;
  margin: -3% 5% 5% 5%;
}
.privacy-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 5% 5% 5%;
  text-align: justify;
}
.privacy-main-title {
  font-weight: 700;
  font-size: 3em;
  // text-transform: uppercase;
  text-align: center;
}

.header-title {
  font-weight: 600;
  font-size: 2em;
}

.newIdent{
  margin-left: 40px!important;
}

.privacy-text {
  font-size:1em;
  font-weight: 400;
}


.privacy-img {
  margin-top: -15%;
}
.rest-para {
  color: #323132;
  opacity: 0.8;
  font-size: 1rem;
}

@media only screen and (max-width: 768px) {
  .overlap-box-privacy
  {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40%;
  }
  .privacy-img {
    margin-top: -48%;
    width: 55%;

  }
  .privacy-title{
    text-align: center;
    padding-top: 3em;
    padding-bottom: 5%;
    font-size: 1.25em;
  }
}

@media only screen and (min-width: 769px) {
  .overlap-box-privacy
  {
    flex-wrap: nowrap;
  }
  .privacy-img {
    margin-top: -15%;
  }
}


@media (max-width: 500px) {
  .overlap-box-privacy
  {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40%;
  }
  .privacy-img {
    margin-top: -48%;
    width: 55%;

  }
  .privacy-title{
    text-align: center;
    padding-top: 3em;
    padding-bottom: 5%;
    font-size: 1.25em;
  }
}

.headerHalf-main-privacy {
  width: 100vw;
  height: 30vh;
  background-image: url("../../../assest/header-img/Platform.jpg");
  background-size: cover;
  background-position: right;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10%;
  color: white;
}

.privacy-overlap-box.extend-it {
  padding-bottom: 80px;
}





// App download css
.cancel-img {
  width: 50%;
  height: 50%;
}
.cancel-btn {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  margin-bottom: 20px;
}
.app-img {
  height: 3em;
}
.download-app-form {
  background-image: url('../../../assest/download_background.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 25em;
  height: 35em;
  margin: 20px auto!important;
}
.bgImg-app {
  height:20em;
}
button.cancel-btn {
  float: right;
}
.app_buttons {
  padding-top: 115%;
  padding-left: 14%;
}

@media (max-width:500px) {
  .app-img {
    height: 1.5em;
  }
  .download-app-form {
    width: 15em;
    height: 20em;
  }
  button.cancel-btn {
    float: right;
  }
  .app_buttons {
    padding-top: 17em;
    padding-left: 25%;
  }
}