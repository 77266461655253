.solution-platform {
  display: flex;
  flex-direction: column;
}

//adfadf
.headerHalf-main-features {
  width: 100vw;
  height: 70vh;
  background-image: url("../../../assest/header-img/feature2.jpg");
  background-size: cover;
  background-position: center center;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10%;
  color: white;
}
.headerHalf-title {
  font-size: 3rem;
  margin: 0;
  line-height: 2.34;
  font-weight: bold;
}
.headerHalf-uris {
  font-size: 1.2rem;
  margin: 0;
  opacity: 0.8;
}
.uris-class {
  border-bottom: 1px solid white;
  margin: 0;
}

@media (max-width: 800px) {
  .headerHalf-main > div {
    position: absolute;
    top: 17%;
  }

  .headerHalf-main {
    padding-top: 60%;
    height: 0px;
  }

  p.headerHalf-title {
    font-size: 1.8rem;
  }
}
.headerHalf-content {
  @media (max-width: 450) {
    top: 10%;
  }
}

.feature-box-left {
  width: 65%;
  z-index: 99;
  align-self: center;
  align-items: center;
  text-align: center;
  color: #323132;
  margin: 1%;
  border-radius: 5px;
  box-shadow: 0 1.5px 8px 0 #6930c31a;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  padding-bottom: 4%;
  background: #fff;
  @media (max-width: 600px) {
    padding-bottom: 100px;
  }
  @media (max-width: 750px) {
    padding-bottom: 300px;
  }
}
.feature-box-right {
  width: 65%;
  align-self: center;
  align-items: center;
  text-align: center;
  color: #323132;
  margin: 1%;
  border-radius: 5px;
  box-shadow: 0 1.5px 8px 0 #6930c31a;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;

  @media (max-width: 600px) {
    padding-bottom: 100px;
  }
  @media (max-width: 750px) {
    padding-bottom: 300px;
  }
}
.title-feature {
  font-weight: 600;
  font-size: 1.5em;
  opacity: 0.9;
  margin-top: 2em;
}

.feature-box-content {
  // display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  // grid-gap: 5%;
  text-align: left;
  margin: 3%;
  margin-top: 3em;
  width: 90%;
}
.display-flex {
  display: flex;
  margin: 5px;
}

.feature-box-image img {
  width: 25em;
  height: 20em;
}

.feature-box-image img {
  width: 30em;
  height: 20em;
  margin: 2em;
}
@media (max-width: 700px) {
  .feature-box-image img {
    width: 16em;
    height: 10em;
    margin-left: 5px;
  }
  .feature-box-left,
  .feature-box-right {
    clear: both;
    display: inline-block;
    padding-bottom: 2em;
    padding: 0;
    margin: 2em;
    width: 90%!important;
  }
  .title-feature {
    font-size: 1em;
    margin: 1em;
  }
  .display-flex {
    display: grid;
    width: 100%;
    padding: 0;
    margin: 0;
  }
}

.key-text-col {
  flex: 85%;
}

.key-icon-col {
  flex: 15%;
}

.kys-platform-content {
  flex: 15%;
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
    }
  }
}

.kys-platform-img {
  flex: 20%;
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
    }
  }
}

.kys-platform-image {
  width: 100%;
  @media (max-width: 600px) {
   // display: none;
  }
}

.kys-div {
  display: flex;
  align-self: center;
  margin: 6%;
  @media (max-width: 700px) {
    display: block;
  }
}

.kys-card {
  // position: relative;
  // background-color: white;
  // align-items: center;
  // justify-content: center;
  border-radius: 5.5px;
  -webkit-border-radius: 5.5px;
  -moz-border-radius: 5.5px;
  -ms-border-radius: 5.5px;
  -o-border-radius: 5.5px;
  display: flex;
  @media (max-width: 500px) {
    margin-left: 5%;
  }
}
.kft-card-icon {
   margin-top: 5px!important;
  //width: 50%;
  align-self: center;
  // @media (max-width: 600px) {
  //   width: 12%;
  //   margin-top: 3%;
  // }
}
.kys-card-title {
  // text-align: center;
  // text-transform: uppercase;
  // margin: -15% 7% 0% 0%;
  color: #9a0cec;
  line-height: 15.5px;
  font-weight: bold;
  opacity: 0.9;
  font-size: 1.1rem;
}
.kys-card-btn {
  height: 50px;
  width: 100%;
  position: absolute;
  background-color: rgba(239, 239, 239, 0.3);
  text-transform: uppercase;
  border: none;
  border-top: 0.5px solid #707070;
  color: #9a0cec;
  font-weight: bold;
  line-height: 1.5;
  // margin-top: 330px;
}
.kys-card-desc {
  text-align: justify;
  color: #323132;
  font-weight: 400;
  font-size: 1rem;
  margin-top: 2%;
  margin-right: 9%;
  margin-bottom: 0%;
  min-height: 8em;

  // margin-left: 23%;
  opacity: 0.8;
}

.whatmore {
  margin-top: 6em !important;
  padding-bottom: 0 !important;
  z-index: 90;
}

.padding-up {
  margin-top: -4em;
}

.features-middle-content {
  margin-top: 2em;
  z-index: 1;
  margin-bottom: -2em;
}

img.features-middle {
  width: 100vw;
}
