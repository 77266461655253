.blogs-main {
  display: flex;
  flex-direction: column;
}
.grid-for-blogs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 80%;
  align-self: center;
  grid-gap: 6%;
  height: 100%;
  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}
.blog-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 50px; // Adjust as needed for spacing
  // //padding: 0 20px;
  // @media (max-width: 900px) {
  //   margin: 20px 30px;
  // }
  // @media (max-width: 600px) {
  //   margin: 20px 30px;
  //   justify-content: center;
  // }
}
// .blog-div:hover{
//   transition: transform 0.3s ease;
//   transform: translateY(-5px); 
// }

// .blog-div {
//   width: calc(33.333% - 20px); // Adjust as needed for spacing
//   margin: 30px 20px; // Adjust as needed for spacing
//   box-sizing: border-box;
// }
.blog-div {
  position: relative;
  // display: flex;
  cursor: pointer;
  flex-direction: column;
  width: 22em !important;
  align-items: center;
  text-align: center;
  border-radius: solid 0.5 #e2e2e2;
  -webkit-border-radius: solid 0.5 #e2e2e2;
  -moz-border-radius: solid 0.5 #e2e2e2;
  -ms-border-radius: solid 0.5 #e2e2e2;
  -o-border-radius: solid 0.5 #e2e2e2;
  box-shadow: 0 1.5px 25px 0 rgba(105, 48, 195, 0.1);
  height: 27em;
  margin: 20px 20px;
  transition: transform 0.3s ease;

  @media(max-width:1200px){
    width: 90%;
    margin: 20px 0;
  };
  @media(max-width:900px){
    width: 100%!important;
    margin: 20px -10px;
  }
}
.category{
  justify-content: space-between;
  
}
.cat1{
  font-weight: 800;
  color: #b5b5b5;
  margin: 5% 8%;
}
.blog-title-sub{
  min-height: 10.2em;
  margin: 30px 0 10px 0;
}
.blog-sub-title{
  text-align: center;
}
.blog-title {
  line-height: 1.22;
  font-weight: 600;
  padding: 1% 5% 1% 5%;
  opacity: 0.8;
  margin: 0;
  text-align: left;
  //min-height: 4.5em;
}
.dateAndAuth {
  display: flex;
  font-family: rubik;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 0;
  margin-bottom: 2%;
  font-size: 0.8rem;
  font-weight: bold;
  opacity: 0.8;
  min-height: 2em;
  bottom: 1%!important;
}
.dateField {
  color: #9a0cec;
  text-align: left;
  margin-right: 5px;
}
.author-field{
  margin-left: 5px;
  text-align: start;
}
.article {
  text-align: left;
  margin: 1% 5% 1% 5%;
  color: #323132;
  opacity: 0.9;
  margin-bottom: 2%;
  font-size: 0.9rem;
  //min-height: 6.5em;
}
.btn-blog {
  // position: absolute;
  width: 20em;
  height: 40px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: none;
  background: #efefef;
  opacity: 0.6;
  color: #9a0cec;
  font-size: 1rem;
  font-weight: bold;
}
.img-for-blog {
  width: 100%;
  height: 40%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
  @media(max-width:900px){
    height: 30%;
  };
}
.filter-pagination {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 4% 10% 5% 10%;
}

.filter-pagination p {
  font-size: 1.5em;
  font-weight: 600;
  opacity: 0.8;
}
//blog
.headerHalf-main-blog {
  width: 100vw;
  height: 70vh;
  background-image: url("../../../assest/header-img/Blog.jpg");
  background-size: cover;
  background-position: center center;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10%;
  color: white;
}
.headerHalf-title {
  font-size: 3rem;
  margin: 0;
  line-height: 2.34;
  font-weight: bold;
}
.headerHalf-uris {
  font-size: 1.2rem;
  margin: 0;
  opacity: 0.8;
}
.uris-class {
  border-bottom: 1px solid white;
  margin: 0;
}

@media (max-width: 800px) {
  .headerHalf-main > div {
    position: absolute;
    top: 17%;
  }

  .headerHalf-main {
    padding-top: 60%;
    height: 0px;
  }

  p.headerHalf-title {
    font-size: 1.8rem;
  }
}
.headerHalf-content {
  @media (max-width: 450) {
    top: 10%;
  }
}

.articleTextBlock p {
  font-family: "Rubik" !important;
  color: #323132 !important;
  align-self: center;
}

.blog-main-1 {
  display: flex;
  flex-direction: column;
}

.blog-i-title {
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: var(--charcoal-grey);
  align-self: center;
  margin-top: 5%;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.53;
  font-weight: 700;
  margin-right: 5%;
  @media (max-width:500px) {
      font-size: 1.1rem;
  }
}

.RectangleBox {
  width: 277.5px;
  height: 5px;
  margin: 2.5px 51.5px 12px 7.8px;
  border-radius: 27.5px;
  background-image: linear-gradient(to right, #9a0cec, #48bfe3 106%);
  align-self: center;
}

.blog-i-header {
  // margin: 0 10em;
}

.blog-img {
  display: block;
  // margin-left: auto;
  // margin-right: auto;
  padding: 20px;
  border-radius: 2.5px;
  width: auto;
  height: 75vh;
  @media(max-width:1200px){
    width: 90%;
  };
  @media(max-width:900px){
    width: 100%;
  }
}

.articleTextBlock {
  margin: 1em;
}

.blog-sub-title {
  margin:1em auto;
  @media (max-width:500px) {
    padding-left:1em;
  }
}

.blog-content {
  padding:5em;
  @media (max-width:500px) {
    padding:1em;
  }
}

.paginationn{
  margin: 10px auto!important;
}