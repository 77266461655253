.overlap-box {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  background-color: white;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: none;
  align-self: center;
  box-shadow: 0 1.5px 18.5px 0 #00000029;
  padding-bottom: 2%;
  @media (max-width: 1000px) {
    padding-bottom: 17%;
  }
  @media (max-width: 600px) {
    padding-bottom: 28%;
  }
}
.box-title {
  align-self: center;
  text-align: center;
  // text-transform: uppercase;
  margin-top: 5%;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.53;
  font-weight: bold;
  margin-left: 5%;
  margin-right: 5%;
  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
}
@media (max-width: 600px) {
  .overlap-box.extend-it {
    padding-bottom: 80px;
  }
}
