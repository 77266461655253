$white: #ffffff;
$bright-magenta: #ee00ff;
$azure: #13b9eb;
.homepage {
  display: block;
  max-width: 100%;
  overflow: hidden;
  padding: 0 0 0.5px;
  background-color: #fef9ff;
  font-family: Titillium Web;
}
.header {
  width: 100vw;
  //vaibhav change
  height: auto;
  padding: 9px 22px 121px 44.5px;
  background-image: url("../../assest/Home/Home2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-position-x: right;
  position: relative;
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    padding: 20px 0 100px 0;
    .text-style-3 {
      padding: 0 20px 0 20px !important;
    }
  }
  @media (max-width: 500px) {
  }
  // @media (max-width: 480px) {
  //   width: 100%;
  //   background-size: contain;
  //   margin-top: 0;
  // }
}
.header-overlap {
  width: 80vw;
  height: auto;
  margin: 0px 7.5% 0px 7.5%;
  border-radius: 2px;
  background-image: url("../../assest/Home/Group\ 741.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 90%;
  z-index: 5;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  padding: 0 10px;
  @media (max-width: 450px) {
    margin-top: -25%;
  }
}
.vedio-content_space {
  width: 100%;
  height: 50em;
  background-image: url("../../assest/Home/network-back_540.png");
  opacity: 0.8;
  // margin-top: -10%;
  padding-top: 10%;
}
.body-content_1 {
  display: flex;
  width: 100%;
  margin-top: 10em;
  flex-direction: column;
  //vaibhav change
  @media (max-width: 1200px) {
    margin-top: 15em;
  }
  @media (max-width: 600px) {
    margin-top: 20em;
    }
  //vaibhav change
}
.body-content_1-text {
  margin: 7% 0 2% 0;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.13;
  align-self: center;
}
.body-content_1-subtext {
  font-weight: 400;
  opacity: 0.8;
  font-size: 0.7em;
  text-align: center;
}

.body-content-1_img {
  margin-top: -25rem;
  width: 100%;
  margin-left: -1.5rem;
}
.face-scan-adobe_1 {
  width: 100vw;
  margin: -2rem 0 0 0;
  opacity: 0.9;
  background-image: url("../../assest/Home/face_id_746.png");
  background-size: cover;
  // @media (max-width: 1279px) {
  //   display: none;
  // }
  // @media (min-width: 1441px) {
  //   display: none;
  // }
}
.body-content_1-card {
  width: 100vw;
}
.face-scan-adobe_2 {
  width: 100vw;
  padding-bottom: 3em;
  background-image: url("../../assest/Home/Group\ 747.png");
  background-size: cover;
}
.brand-support {
  width: 100vw;
      margin-bottom: -6em;
}
.footer {
  width: 100vw;
}
@media only screen and (max-width: 1157px) {
  .homepage {
    font-size: 12px;
  }
}

// @media (min-width: 1200px) {
//   .body-content_card-1 {
//     min-width: 300px;
//     height: 350px;
//   }
// }

.static-card {
  margin-bottom: 120px;
}

@media (max-width: 768px) {
  .body-content_card {
    display: block !important;
    position: unset !important;
  }

  .body-content_card-1 {
    width: 100% !important;
    /* display: block; */
    margin: 25px 0px;
  }

  .body-content {
    height: 100%;
  }

  .body-content_text {
  }

  img.body-content-2_img {
    display: none;
  }

  p.body-content_text-2 {
    width: 80%;
    font-size: 1em;
  }

  .body-content_text {
    text-align: center;
  }

  .face-scan-adobe_2 {
    display: flex;
    align-items: center;
  }
  .faceId-content_text {
    width: 100%;
  }

  .faceId-content_dynamic {
    display: block !important;
  }

  .faceId-content_dynamic {
    margin: 0px !important;
  }
  .static-card {
    display: block;
  }

  .static-card_1:not(:first-child) {
    margin-top: 254px;
  }

  // .face-scan-adobe_1 {
  //   margin-top: 440px;
  // }

  .faceId-content_dynamic {
    display: block;
    width: 100%;
    text-align: center;
    margin: 0px;
  }

  .faceId-content_text {
    width: 100% !important;
    text-align: center;
  }

  .faceId-content_dynamic-1 {
    display: block;
    padding: 10px 0px;
    text-align: center;
    margin-top: -30px;
  }

  img.faceId-content_dynamic-1-img {
    width: 60%;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 0px;
    margin-right: 0px;
  }

  p.faceId-content_dynamic-1-desc {
    margin: 0px;
    padding: 0px;
    width: 100%;
    MIN-HEIGHT: 6em;
    font-size: 1em;
  }

  img.phone__img {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .header-overlap {
    // height: 150px;
    width: 96%;
    left: 2%;
    margin: 0px;
    background-repeat: repeat;
  }

  .overlap-text {
    width: 61%;
  }
  .vedio-content_space {
    margin-top: 20%;
  }

  .vedio-content {
    width: 100%;
    height: 100%;
    margin: 0px;
  }
}

@media (max-width: 500px) {
  .overlap-text {
    width: 100%;
  }
  .header-overlap {
    // height: 160px;
    // width: 80%;
  }

  .overlap-content {
    justify-content: center;
    text-align: center;
  }

  .vedio-content_space {
    height: 300px;
  }
}

@media only screen and (max-width: 510px) {
  .vedio-content_space {
    margin-top: 29% !important;
  }
}

// UPDATED CODE
@media only screen and (max-width: 768px) {
  .header {
    background-position: right;
  }
  .header-content {
    width: 100%;
    margin-right: 0;
    margin-top: 20%;
    margin-left: 0;
    padding: 0;
    .content-1 {
      width: 100%;
      margin: 0;
      height: unset;
      text-align: center;
      .text-style-1,
      .text-style-2 {
        width: 100%;
        padding-left: 0;
      }
    }
    .content-2 {
      width: 100%;
      padding: 0;
      height: unset;
      margin: 10px 0;
      text-align: center;
      .text-style-3 {
        width: 100%;
        padding-left: 0;
      }
    }
    .header-button {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .button-header_1,
      .button-header_2 {
        margin: 0;
        margin-bottom: 20px;
      }
    }
  }
  .vedio-content_space {
    margin-top: 5%;
  }
  .body-content_1-text {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
  }
  .body-content_1-card {
    .static-card_1 {
      height: unset;
      margin: 20px 20px 0 20px;
    }
    .phone__img {
      display: none;
    }
  }
  .body-content-1_img {
    margin-top: 0;
    display: none;
  }

  .brand-support {
    .brand-content {
      .brand-content-text_2 {
        font-size: 3rem;
      }
      .brand-content-text_3,
      .brand-content-text_1 {
        font-size: 1.5rem;
      }
      .brand-img {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 40px 0;
        img {
          width: 135px;
          height: auto;
        }
      }
      .brand-content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-right: 0;
        .brand-content-box-img {
          width: 70%;
          height: auto;
          margin-top: -20%;
          margin-left: 0;
        }
        .brand-content-box_container {
          padding-top: 0;
          padding: 0 10%;
          display: flex;
          flex-direction: column;
          align-items: center;

          .brand-text_quote-auth {
            margin-top: 5%;
            text-align: center;
          }
          .brand-name {
            text-align: center;
          }
          .brand-btn-card {
            margin: 4% 0 4% 0;
            text-align: center;
            padding: 0;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 769px) {
}

@media (max-width: 500px) {
  p.faceScan-content_title {
    font-size: 1.3em;
  }
  .faceScan-content_desc {
    font-size: 1.2em;
  }
  .faceScan-content_feat {
    font-size: 3.5em;
  }
  .faceScan-link-block {
    margin: 0 auto;
    width: 50%;
    display: block !important;
  }
}
