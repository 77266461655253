.cancel-img {
  width: 50%;
  height: 50%;
}
.cancel-btn {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  margin-bottom: 20px;
}
.player-box {
  margin-bottom: 20px;
  width: 20em;
  @media (max-width:900px) {
    width: 100% !important;    
  }
}
