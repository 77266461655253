.brand-logo {
  width: 6em;
}
nav.navbar {
  background-color: transparent;
  // padding: 10px calc(10% - 10px);
  padding: 6.5px 5% 6.5px calc(4% - 10px);
  @media (max-width: 600px) {
    background-color: transparent;
  }
}

#responsive-navbar-nav {
  a {
    color: #9a0cec;
    font-weight: 500;
  }
  .nav-item,
  .navbar-nav > .nav-link {
    padding-left: 20px;
    padding-right: 20px;
  }
  .download-app {
    margin-left: 2.5%;
    a {
      background-color: #fff;
      font-weight: 600;
      background: linear-gradient(91deg, #9a0cec 0%, #48bfe3 100%);
      border-radius: 5px;
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  #talktoexpert{
    margin-left: 2.5%;
    a {
      background-color: #fff;
      font-weight: 600;
      background: linear-gradient(91deg, #9a0cec 0%, #48bfe3 100%);
      border-radius: 5px;
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  #talktoexpert #ttaeLink.active{
    
    
  }
  

  div.navbar-nav.active, div.nav-item.active {
    border-radius: 5px;
    background-color: #fff;
    & > a {
      font-weight: 600;
      color: #b273d8 !important;
    }
  }
}

nav.active {
  background-color: #343a40;
  div.nav-item.active {
    background-color: #343a40 !important;
  }
  .nav-item {
    & > a {
      color: #f2f2f2 !important;
    }
  }
  .nav-link {
    color: #f2f2f2 !important;
  }
}

.dropdown-toggle::after {
  display: none !important;
}

.navbar-light .navbar-toggler {
  background-color: white;
}

.nav-link,
.dropdown > .nav-link {
  color: #fff !important;
  font-weight: 600 !important;
}


.dropdown-item.active, .dropdown-item:active {
  background-color: #48bfe3 !important;
  color:white !important;
}

@media (max-width:1000px) {
  div.active.navbar-nav {
    background-color: transparent !important;
  }
}