.blogs-main {
  .overlap-box-with-filters {
    z-index: 1;
  }
}

.top-banner {
  text-align: left;
  font-family: Rubik;
  color: #9a0cec;
  line-height: 1.67;
  // text-transform: uppercase;
  font-size: 0.6rem;
  margin: 3% 0 2% 5%;
}
.title-for-webinar {
  text-align: left;
  padding: 7% 7% 3.5%;
  color: #323132;
  font-weight: 600;
  line-height: 1.22;
  margin: 0px;
  font-size: 1.1rem;
}

.date-for-webinar {
  color: #9a0cec;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 0% 7% 7%;
  margin: 0px;
}

.blog-div-card {
  // height: 550px;
  border: 1px solid #ccc;
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.btn-webinar {
  justify-self: flex-end;
  width: 100%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: #f8f8f8;
  border: none;
  border-radius: 0px;
  border-top: 1px solid #ccc;
  color: #9a0cec;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 20px;
  outline: 0 !important;
  opacity: 1;
}
.img-for-webinar {
  width: 100%;
  height: auto;
  object-fit: contain;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

//webinar
.headerHalf-main-webinar {
  width: 100vw;
  height: 70vh;
  background-image: url("../../../assest/header-img/Events.jpg");
  background-size: cover;
  background-position: center center;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10%;
  color: white;
}
.headerHalf-title {
  font-size: 3rem;
  margin: 0;
  line-height: 2.34;
  font-weight: bold;
}
.headerHalf-uris {
  font-size: 1.2rem;
  margin: 0;
  opacity: 0.8;
}
.uris-class {
  border-bottom: 1px solid white;
  margin: 0;
}

@media (max-width: 800px) {
  .headerHalf-main > div {
    position: absolute;
    top: 17%;
  }

  .headerHalf-main {
    padding-top: 60%;
    height: 0px;
  }

  p.headerHalf-title {
    font-size: 1.8rem;
  }
}
.headerHalf-content {
  @media (max-width: 450) {
    top: 10%;
  }
}

.event-tab {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 4% 10% 5% 10%;

  .event-btn_1 {
    align-self: center;
    border: none;
    width: 12em;
    // height: 7%;
    text-transform: uppercase;
    margin-top: 4%;
    line-height: 1;
    letter-spacing: 0.3px;
    color: #333;
    background-color: #fff;
    font-weight: bold;
    border: 1px solid #c1c1c1;
    &.active {
      color: white;
      background: linear-gradient(91deg, #9a0cec 0%, #48bfe3 100%);
    }
    @media (max-width: 900px) {
      // width: 25%;
    }
    @media (max-width: 600px) {
      width: 30%;
      p {
        font-size: 0.6em;
      }
    }
    .elinks {
      display: inline-block;
      padding: 15px;
      &,
      &:focus {
        text-decoration: none !important;
        color: inherit;
      }

      p {
        font-weight: bold;
        margin-bottom: 0px;
      }
    }
  }
}

.grid-for-webinars {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 90%;
  max-width: 1400px;
  align-self: center;
  margin-top: 0px;
  height: 100%;
  grid-gap: 2em;
  @media (max-width: 500px) {
    margin: 2em;
    grid-template-columns: 1fr;
  }
}

.events-register {
  min-height: 100vh;
  background-color: black;
  background-image: url("../../../assest/header-img/Events.jpg");
  background-size: cover;
  background-position: center center;
  padding: 2% 15%;
  margin: 5em auto;
  .title {
    font-weight: bold;
    color: #fff;
    font-size: 2.5rem;
    padding-top: 10%;
  }
  .subtitle {
    color: #fff;
  }
  form {
    margin-top: 3em;
    max-width: 30em;
    // width: 20em;
    @media (max-width: 500px) {
      max-width: 20em;
    }
    .form-group {
      label {
        color: #fff;
      }
      input {
        background-color: rgba(255, 255, 255, 0.199);
        border: 2px solid #fff;
        color: #fff;
      }
    }

    .submit-register-button {
      width: 100%;
      padding: 15px;
      border-radius: 5px;

      text-align: center;
      border: 0px;
      margin-top: 40px;
      outline: 0;
      background: linear-gradient(91deg, #9a0cec 0%, #48bfe3 100%);
      color: #fff;
    }
  }
}
