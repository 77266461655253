.headerHalf-main-pl {
  width: 100vw;
  height: 70vh;
  background-image: url('../../../assest/header-img/passwordless-banner.jpg');
  background-size: cover;
  background-position: center center;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10%;
  color: white;
}
.headerHalf-title {
  font-size: 3rem;
  margin: 0;
  line-height: 2.34;
  font-weight: bold;
}
.headerHalf-uris {
  font-size: 1.2rem;
  margin: 0;
  opacity: 0.8;
}
.uris-class {
  border-bottom: 1px solid white;
  margin: 0;
}

@media (max-width: 800px) {
  .headerHalf-main-palm > div {
    position: absolute;
    top: 10%;
  }

  .headerHalf-main-palm {
    padding-top: 60%;
    height: 0px;
  }

  p.headerHalf-title {
    font-size: 1.8rem;
  }
}
.headerHalf-content {
  @media (max-width: 450) {
    top: 10%;
  }
}


.feature-box-rightspl {
  //display: flex;
  margin-top: 100px;
  padding-bottom: 50px;
  width: 90%!important;
  height: 100%;
 background-color: white;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: none;
  align-self: center;
  box-shadow: 0 1.5px 18.5px 0 #00000029;


  .title-feature {
    text-align: center;
    font-weight: 600;
    font-size: 1.5em;
    opacity: 0.9;
    margin-top: 2em;
  }
 
  
  .desc1-feature {
    margin: 20px 50px 0 50px;
  }
  //   .overallcontent{
  //     margin-bottom: 50px;
  //   }
  //   .overallcontent{
  //     margin-bottom: 50px;
  //   }
  
  //   .content1-feature {
  //     display: grid;
  //     grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  //     grid-gap: 5%;
  //     text-align: left;
  //     width: 90%;
  //   }
  .feature-box-contentpl {
    
    text-align: left;
    margin: 3%;
    margin-top: 1em;
    //width: 90%;
  
   
    
    //   .feature-box-image1 img {
    //     width: 25em;
    //     height: 20em;
    //   }
    
    // .feature-box-images img {
    //   width: 21em!important;
    //   height: 21em!important;
    //   margin-right: 2em;
    // }
  
    .feature-box-images img {
      width: 30em!important;
      height: 30em!important;
      //margin: 2em;
      margin-right: 0!important;
    }

   
    .title1-feature{
      font-weight: 600;
      font-size: 1.2em;
      opacity: 0.9;
      //margin-right: 20px!important;
    }
    .content1-feature {
      // display: grid;
      // grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
      // grid-gap: 5%;
      text-align: left;
      width: 100%!important;
     // margin-right: 20px!important;
    
    }
    
    
    //   .feature-box-image1 img {
    //     width: 25em;
    //     height: 20em;
    //   }
  }
  
  @media (max-width: 700px) {
    .feature-box-images img {
      width: 18em;
      height: 10em;
      margin-left: 5px;
    }
    .feature-box-left,
    .feature-box-rights {
      clear: both;
      display: inline-block;
      padding-bottom: 2em;
      padding: 0;
      margin: 2em;
      width: 90%;
    }
    .title-feature {
      font-size: 1em;
      margin: 1em;
    }
   
  }
  
  
  
  
 
  
  
  @media (max-width: 700px) {
    .feature-box-images img {
      width: 18em!important;
      height: 18em!important;
    }
    .feature-box-left,
    .feature-box-rights {
      clear: both;
      display: inline-block;
      padding-bottom: 2em;
      padding: 0;
      margin: 2em;
      width: 90%;
    }
    .title-feature {
      padding-right: 20px;
      font-size: 1em;
      margin: 1em;
    }
    .display-flex {
      display: grid;
      width: 100%;
      padding: 0;
      margin: 0;
    }
  }

  @media (max-width: 600px) {
    padding-bottom: 100px;
  }
  @media (max-width: 750px) {
    padding-bottom: 300px;
  }
}


.feature-box-rights2 {
  display: flex;
  margin-top: 100px;
  padding-bottom: 50px;
  flex-direction: column;
  width: 90%!important;
  height: 100%;
 background-color: white;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: none;
  align-self: center;
  box-shadow: 0 1.5px 18.5px 0 #00000029;


  .title-feature {
    text-align: center;
    font-weight: 600;
    font-size: 1.5em;
    opacity: 0.9;
    margin-top: 2em;
  }
  .plsec3{
    display: flex;
    justify-content: center!important;
  }
  //   .overallcontent{
  //     margin-bottom: 50px;
  //   }
  
  //   .content1-feature {
  //     display: grid;
  //     grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  //     grid-gap: 5%;
  //     text-align: left;
  //     width: 90%;
  //   }
  .feature-box-contentpl {
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    // grid-gap: 5%;
    text-align: left;
    margin: 3%;
    margin-top: 1em;
    //width: 90%;
  
   
    
    //   .feature-box-image1 img {
    //     width: 25em;
    //     height: 20em;
    //   }
    
    // .feature-box-images img {
    //   width: 21em!important;
    //   height: 21em!important;
    //   margin-right: 2em;
    // }
  
    .feature-box-images img {
      width: 30em!important;
      height: 30em!important;
      //margin: 2em;
      //margin-top: 300px!important;
    }
  }
  
  @media (max-width: 700px) {
    .feature-box-images img {
      width: 18em;
      height: 10em;
      margin-left: 5px;
    }
    .feature-box-left,
    .feature-box-rights {
      clear: both;
      display: inline-block;
      padding-bottom: 2em;
      padding: 0;
      margin: 2em;
      width: 90%;
    }
    .title-feature {
      font-size: 1em;
      margin: 1em;
    }
    .display-flex {
      display: grid;
      width: 100%;
      padding: 0;
      margin: 0;
    }
  }
  
  
  
  
  .title-feature {
    font-weight: 600;
    font-size: 1.5em;
    opacity: 0.9;
    margin-top: 1em;
  }
  
  .desc1-feature {
    margin: 20px 50px 0 50px;
  }
  //   .overallcontent{
  //     margin-bottom: 50px;
  //   }
  .title1-feature{
    font-weight: 600;
    font-size: 1.2em;
    opacity: 0.9;
    //margin-right: 20px!important;
  }
  .content1-feature {
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    // grid-gap: 5%;
    text-align: left;
    width: 100%!important;
   // margin-right: 20px!important;
  
  }
  
  
  //   .feature-box-image1 img {
  //     width: 25em;
  //     height: 20em;
  //   }
  
  
  @media (max-width: 700px) {
    .feature-box-images img {
      width: 18em!important;
      height: 18em!important;
    }
    .feature-box-left,
    .feature-box-rights {
      clear: both;
      display: inline-block;
      padding-bottom: 2em;
      padding: 0;
      margin: 2em;
      width: 90%;
    }
    .title-feature {
      padding-right: 20px;
      font-size: 1em;
      margin: 1em;
    }
    .display-flex {
      display: grid;
      width: 100%;
      padding: 0;
      margin: 0;
    }
  }

  @media (max-width: 600px) {
    padding-bottom: 100px;
  }
  @media (max-width: 750px) {
    padding-bottom: 300px;
  }
}

.feature-box-rights3 {
  display: flex;
  margin-top: 100px;
  flex-direction: column;
  width: 90%!important;
  height: 100%;
 background-color: white;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: none;
  align-self: center;
  box-shadow: 0 1.5px 18.5px 0 #00000029;


  .title-feature {
    text-align: center;
    font-weight: 600;
    font-size: 1.5em;
    opacity: 0.9;
    margin-top: 2em;
  }
 
.newsec3{
  display: flex!important;
}
  .desc1-feature {
    margin: 20px 50px 0 50px;
  }
  //   .overallcontent{
  //     margin-bottom: 50px;
  //   }
  //   .overallcontent{
  //     margin-bottom: 50px;
  //   }
  
  //   .content1-feature {
  //     display: grid;
  //     grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  //     grid-gap: 5%;
  //     text-align: left;
  //     width: 90%;
  //   }
  .feature-box-contentpl3 {
    
    text-align: left;
    margin: 3%;
    margin-top: 1em;
    //width: 90%;
  
   
    
    //   .feature-box-image1 img {
    //     width: 25em;
    //     height: 20em;
    //   }
    
    // .feature-box-images img {
    //   width: 21em!important;
    //   height: 21em!important;
    //   margin-right: 2em;
    // }
  
    .feature-box-images img {
      width: 30em!important;
      height: 30em!important;
      //margin: 2em;
      margin-right: 0!important;
    }

   
    .title1-feature3{
      font-weight: 600;
      font-size: 1.2em;
      opacity: 0.9;
      //margin-right: 20px!important;
    }
    .content1-feature3 {
      // display: grid;
      // grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
      // grid-gap: 5%;
      text-align: left;
      width: 100%!important;
     // margin-right: 20px!important;
    
    }
    
    
    //   .feature-box-image1 img {
    //     width: 25em;
    //     height: 20em;
    //   }
  }
  
  @media (max-width: 700px) {
    .feature-box-images img {
      width: 18em;
      height: 10em;
      margin-left: 5px;
    }
    .feature-box-left,
    .feature-box-rights {
      clear: both;
      display: inline-block;
      padding-bottom: 2em;
      padding: 0;
      margin: 2em;
      width: 90%;
    }
    .title-feature {
      font-size: 1em;
      margin: 1em;
    }
   
  }
  
  
  
  
 
  
  
  @media (max-width: 700px) {
    .feature-box-images img {
      width: 18em!important;
      height: 18em!important;
    }
    .feature-box-left,
    .feature-box-rights {
      clear: both;
      display: inline-block;
      padding-bottom: 2em;
      padding: 0;
      margin: 2em;
      width: 90%;
    }
    .title-feature {
      padding-right: 20px;
      font-size: 1em;
      margin: 1em;
    }
    .display-flex {
      display: grid;
      width: 100%;
      padding: 0;
      margin: 0;
    }
  }

  @media (max-width: 600px) {
    padding-bottom: 100px;
  }
  @media (max-width: 750px) {
    padding-bottom: 300px;
  }
}