.solution-platform {
  display: flex;
  flex-direction: column;
}
//
.headerHalf-main-platform {
  width: 100vw;
  height: 70vh;
  background-image: url("../../../assest/header-img/Platform.jpg");
  background-size: cover;
  background-position: right;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10%;
  color: white;
}
.headerHalf-title {
  font-size: 3rem;
  margin: 0;
  line-height: 2.34;
  font-weight: bold;
}
.headerHalf-uris {
  font-size: 1.2rem;
  margin: 0;
  opacity: 0.8;
}
.uris-class {
  border-bottom: 1px solid white;
  margin: 0;
}

@media (max-width: 800px) {
  .headerHalf-main > div {
    position: absolute;
    top: 17%;
  }

  .headerHalf-main {
    padding-top: 60%;
    height: 0px;
  }

  p.headerHalf-title {
    font-size: 1.8rem;
  }
}
.headerHalf-content {
  @media (max-width: 450) {
    top: 10%;
  }
}

.identity-assurance {
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, #9a0cec, #48bfe3 84%);
  color: white;
  height: 100%;
  margin-top: -6em;
  height: 20em;  
}

.identity-assurance-content {
  display: grid;
  margin: 5% 10% 5% 10%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10%;
  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    display: block;
    text-align: center;
  }
}
.kys-div {
  display: flex;
  align-self: center;
  margin: 6%;
  @media (max-width: 700px) {
    display: block;
  }
}

.kys-platform-img-top {
  margin: 40px 0;
  display: flex;
  justify-content: center;
}

.kys-platform-image {
  max-width: 50%;
  height: auto;
  @media (max-width: 700px) {
    max-width: 100%!important;
      margin: 0 10px;
  }
}

.kys-card {
  // position: relative;
  // background-color: white;
  // align-items: center;
  // justify-content: center;
  border-radius: 5.5px;
  -webkit-border-radius: 5.5px;
  -moz-border-radius: 5.5px;
  -ms-border-radius: 5.5px;
  -o-border-radius: 5.5px;
  display: flex;
  @media (max-width: 500px) {
    margin-left: 5%;
  }
}
.kys-card-icon {
  // margin-top: 60px;
  //width: 50%;
  align-self: center;
  // @media (max-width: 600px) {
  //   width: 12%;
  //   margin-top: 3%;
  // }
}
.kys-card-title {
  // text-align: center;
  // text-transform: uppercase;
  // margin: -15% 7% 0% 0%;
  color: #9a0cec;
  line-height: 15.5px;
  font-weight: bold;
  font-size: 1.1rem;
  opacity: 0.9;
}
.kys-card-btn {
  height: 50px;
  width: 100%;
  position: absolute;
  background-color: rgba(239, 239, 239, 0.3);
  text-transform: uppercase;
  border: none;
  border-top: 0.5px solid #707070;
  color: #9a0cec;
  font-weight: bold;
  line-height: 1.5;
  // margin-top: 330px;
}
.kys-card-desc {
  text-align: justify;
  color: #323132;
  font-weight: 400;
  font-size: 1rem;
  margin-top: 2%;
  margin-right: 9%;
  margin-bottom: 0%;
  min-height: 8em;
  // margin-left: 23%;
  opacity: 0.8;
}

.kdt-card-desc {
  text-align: left;
  color: #323132;
  font-weight: 400;
  font-size: 1rem;
  margin-top: 2%;
  margin-right: 9%;
  margin-bottom: 0%;
  // margin-left: 23%;
  opacity: 0.8;
}
.cards-div-title {
  font-size: 1em;
}

.overlap-box-platform {
  margin-top: 7% !important;
}

.platform-content {
  margin: 2% 10% 3% 10%;
  text-align: center;
}

.cards-div-platform {
  display: grid;
  height: auto;
  grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
  grid-gap: 3em 3em;
  margin-top: 3%;
  align-self: center;
}

.medium-card-icon-platform {
  margin: 3em 0em 1em 0em;
  width: 3em;
  height: 3em;
  align-self: center;
  // @media (max-width: 600px) {
  //   width: 12%;
  //   margin-top: 3%;
  // }
}

.key-text-col {
  flex: 85%;
}

.key-icon-col {
  flex: 15%;
}

.kys-platform-content {
  flex: 15%;
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
    }
  }
}

.kys-platform-img {
  flex: 20%;
  //margin-top: 150px;
  @media screen and (max-width: 600px) {
    margin-top: 20px;
    .column {
      width: 100%;
    }
  }
}

.kys-platform-image {
  width: 100%;
  @media (max-width: 600px) {
  }
}

.medium-card-desc-platform {
  text-align: left!important;
  color: #323132;
  font-weight: 400;
  font-size: 0.95rem;
  margin: 3% 5% 5% 5%;
  min-height: 8em;
  opacity: 0.8;
}

.no-bottom-padding{
  padding-bottom: 0 !important;
}

.extend-it {
  z-index: 1;
}

.feature-box-images img {
  margin-right: 80px!important;
  width: 30em!important;
  height: 30em!important;
  //margin: auto 60px auto 0!important;
}
@media (max-width: 700px) {
  .feature-box-images img {
    max-width: 90%!important;
    margin-bottom: 20px;
    margin: 0 15px 20px 15px;
    height: auto!important;
    float: left;
  }
}